import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getChecklist } from './actions';
import {
  selectChecklistForEditing,
  selectFilesByStepIndex,
  selectUploadingFilesByStepIndex,
} from './selectors';
import { FileCategory, FormikValues } from './types';
import { transformChecklistToFormikValues } from './utils';

export const useSelectFilesData = (stepIndex: number): StepFilesData => {
  const filesByStepIndex = useSelector(selectFilesByStepIndex);

  return filesByStepIndex[stepIndex] || {};
};

export const useSelectIsFileUploading = (
  stepIndex: number,
  category: FileCategory
): boolean => {
  const uploadingFilesByStepIndex = useSelector(
    selectUploadingFilesByStepIndex
  );
  return uploadingFilesByStepIndex?.[stepIndex]?.[category];
};

export const useInitializeFormikForEditMode = ({
  editChecklistId,
  stepIds,
  addStepId,
  formik,
}: {
  editChecklistId: string;
  stepIds: string[];
  addStepId: () => string;
  formik: FormikProps<FormikValues>;
}): any => {
  const dispatch = useDispatch();

  const checklistForEditing = useSelector(selectChecklistForEditing);

  useEffect(() => {
    if (editChecklistId) {
      dispatch(getChecklist(editChecklistId));
    }
  }, [editChecklistId]);

  useEffect(() => {
    checklistForEditing?.steps.forEach((item, index) => {
      if (stepIds[index]) return;

      addStepId();
    });
  }, [checklistForEditing]);

  const [isFormikInitialized, setIsFormikInitialized] =
    useState<boolean>(false);

  useEffect(() => {
    if (isFormikInitialized) return;

    if (checklistForEditing?.steps.length === stepIds.length) {
      formik.setValues(
        transformChecklistToFormikValues(checklistForEditing, stepIds)
      );
      setIsFormikInitialized(true);
    }
  }, [checklistForEditing, stepIds]);
};
