import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  button: {
    marginRight: 24,
    padding: '16px 24px',
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 600,
    '&.save': {
      marginRight: 16,
      padding: 16,
      borderColor: theme.palette.secondary.main,
    },
  },
  note: {
    fontSize: 12,
    lineHeight: 1.25,
    color: theme.palette.secondary.light,
  },
}));
