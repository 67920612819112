import MuiDrawer from '@material-ui/core/Drawer';

import MenuList from './MenuList';

import useStyles from './styles';

interface Props {
  open: boolean;
}

function Drawer({ open }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <MuiDrawer
      variant='persistent'
      anchor='left'
      open={open}
      className={classes.drawer}
    >
      <MenuList />
    </MuiDrawer>
  );
}

export default Drawer;
