import { all } from 'redux-saga/effects';

import auth from '../components/Auth/sagas';
import equipment from '../components/EquipmentPage/sagas';
import people from '../components/PeoplePage/sagas';
import videoCall from '../components/VideoCall/sagas';
import checklistForm from '../components/ChecklistForm/sagas';
import checklistsPage from '../components/ChecklistsPage/sagas';
import ticket from '../components/Ticket/sagas';

export default function* rootSaga(): any {
  yield all([
    auth(),
    equipment(),
    people(),
    videoCall(),
    checklistForm(),
    checklistsPage(),
    ticket(),
  ]);
}
