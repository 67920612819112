import Wizard from '../EquipmentPage/Wizard';
import MainLayout from '../MainLayout';

const EditEquipmentWizard = (): JSX.Element => (
  <MainLayout>
    <Wizard isEditMode />
  </MainLayout>
);

export default EditEquipmentWizard;
