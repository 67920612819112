import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  modal: {
    '& .MuiDialog-paper': {
      padding: '16px',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  confirm: {
    backgroundColor: '#D32F2F',
    color: '#FFF',
  },
}));
