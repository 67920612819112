import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  menuRoot: {
    maxHeight: '256px',
    paddingRight: 0,
  },
  menuContainer: {
    '& .MuiListItemIcon-root': {
      minWidth: '30px',
    },
  },
}));
