import { useState } from 'react';
import { Button } from '@material-ui/core';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectСallingMode } from '../../selectors';
import useStyles from './styles';
import insertToDOM from '../../utils/insertToDOM';
import defaultAvatar from '../../../PeoplePage/assets/default-avatar.png';
import {
  socketStartCall,
  socketUserIsBusy,
  socketEndCall,
  setCallingMode,
  setRemoteStream,
  // ringtoneSource,
} from '../../actions';
import Modal from './Modal';
import { RPCHandler, Signaling } from '../../utils';
import { SignalingMessageType } from '../../constants';
import { selectUid } from '../../../Auth/selectors';
import RingtonePlayer from './RingtonePlayer';

interface Props {
  user: User;
}

function stateCallback(state: string): void {
  if (state === 'failed') {
    console.error('stateCallback: failed');
  }
  if (state === 'complete') {
    console.info('stateCallback: complete');
  }
}

const CallingModeModal = ({ user }: Props): JSX.Element | null => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const myUid = useSelector(selectUid);
  const [isPlaying, setIsPlaying] = useState(true);

  const callingMode = useSelector(selectСallingMode);
  const { photoUrl, displayName, email, uid } = user;
  const userName = isEmpty(displayName) ? email : displayName;

  const avatar = isEmpty(photoUrl) ? defaultAvatar : photoUrl;

  const onAccept = (): void => {
    dispatch(setCallingMode('stopped'));
    setIsPlaying(false);
    const mediaCallback = (ev: RTCTrackEvent): void => {
      // ev is our remote track, video or audio
      // adjust UI and add it
      dispatch(setRemoteStream(ev.streams[0]));
    };
    const rpcInstance = RPCHandler.getInstance();

    rpcInstance.startAnswer(myUid, uid, mediaCallback, stateCallback);

    Signaling.emit(SignalingMessageType.ACCEPT, { to: uid });
  };

  const onCancel = (): void => {
    // dispatch(ringtoneSource('./assets/ringtones/stopped.mp3'));
    dispatch(socketEndCall());
    setIsPlaying(false);
    Signaling.emit(SignalingMessageType.END_CALL, { to: user.uid });
  };

  const onDecline = (): void => {
    setIsPlaying(false);
    dispatch(socketUserIsBusy(user));
  };

  const onStart = (): void => {
    setIsPlaying(true);
    dispatch(socketStartCall(user));
  };

  const renderModal = (mode: CallingMode): any =>
    ({
      incoming: (
        <Modal avatar={avatar} userName={userName}>
          <>
            <Button
              className={clsx(classes.button, classes.reject)}
              onClick={onDecline}
            >
              Decline
            </Button>
            <Button
              className={clsx(classes.button, classes.accept)}
              onClick={onAccept}
            >
              Accept
            </Button>
          </>
        </Modal>
      ),
      outcoming: (
        <Modal avatar={avatar} userName={userName}>
          <Button variant='outlined' onClick={onCancel}>
            Cancel
          </Button>
        </Modal>
      ),
      busy: (
        <Modal avatar={avatar} userName={userName} busy>
          <>
            <Button variant='outlined' onClick={onCancel}>
              Close
            </Button>
            <Button
              className={clsx(classes.button, classes.accept)}
              onClick={onStart}
            >
              Retry
            </Button>
          </>
        </Modal>
      ),
      stopped: null,
    }[mode]);

  return createPortal(
    <>
      <RingtonePlayer
        isPlaying={isPlaying}
        source={`./assets/ringtones/${callingMode}.mp3`}
      />
      {renderModal(callingMode)}
    </>,
    insertToDOM('call-popup-root')
  );
};

export default CallingModeModal;
