import clsx from 'clsx';

import { TableCell } from '@material-ui/core';
import useStyles from '../styles';

interface Props {
  isFirstSensor: boolean;
  isLastSensor: boolean;
  deviceId: string;
}

export const FirstCell = ({
  isFirstSensor,
  isLastSensor,
  deviceId,
}: Props): JSX.Element => {
  const classes = useStyles();

  return isFirstSensor ? (
    <TableCell className={classes.withoutBorder}>{deviceId}</TableCell>
  ) : (
    <TableCell className={clsx({ [classes.withoutBorder]: !isLastSensor })} />
  );
};
