import { Button, Box, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

export interface Options {
  title: string;
  iconStyles: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  onClick?(): void;
}

interface Props {
  options: Options[];
  className: string;
}

const ButtonsContainer = ({ options, className }: Props): JSX.Element => (
  <Box className={className}>
    {options.map(({ onClick, title, icon: Icon, iconStyles }) => (
      <Button onClick={onClick} key={title}>
        <Icon className={iconStyles} />
        {title}
      </Button>
    ))}
  </Box>
);

export default ButtonsContainer;
