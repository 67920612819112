import { string, array, object } from 'yup';

import { FormikValues } from './types';

const yupInstance = string();
type YupInstance = typeof yupInstance;

const getRangeValidationMessage = (min: number, max: number): string =>
  `Value should be between ${min} and ${max} symbols`;

const withRange = (yupString: YupInstance, min: number, max: number): any => {
  const message = getRangeValidationMessage(min, max);

  return yupString.min(min, message).max(max, message).nullable();
};

const requiredValidationMessage = 'Mandatory field';

const stepShape: Shape<BaseStep> = {
  title: withRange(string(), 3, 200).required(requiredValidationMessage),
  description: withRange(string(), 3, 500),
};
const stepSchema = object().shape(stepShape);

const formShape: Shape<FormikValues> = {
  name: withRange(string(), 3, 200).required(requiredValidationMessage),
  steps: array().of(stepSchema).min(1).required(),
};
const validationSchema = object().shape(formShape);

export default validationSchema;
