const initialLocation: EquipmentLocation = {
  country: '',
  city: '',
  factory: '',
  floor: '',
  room: '',
  name: '',
};

export const getCreateEquipmentPayload = (
  equipment: EquipmentGeneralInformation
): CreateEquipmentPayload => ({
  ...equipment,
  location: {
    ...initialLocation,
    name: equipment.location,
  },
});
