import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { resourcesApi } from '../../services';
import { setIsLoading } from '../Loader/actions';

import {
  FETCH_CHECKLISTS,
  DELETE_CHECKLIST,
  fetchChecklistsSuccess,
} from './actions';

function* fetchChecklists(): SagaIterator {
  yield put(setIsLoading(true));
  try {
    const { data } = yield call(resourcesApi.get, '/checklists');

    yield put(fetchChecklistsSuccess(data?.checklists));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(setIsLoading(false));
  }
}

function* deleteChecklist({ payload }: Action<string>): SagaIterator {
  yield put(setIsLoading(true));
  try {
    yield call(resourcesApi.delete, `/checklists/${payload}`);

    yield call(fetchChecklists);
  } catch (error) {
    console.error(error);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function* (): SagaIterator {
  yield all([
    takeLatest(FETCH_CHECKLISTS, fetchChecklists),
    takeLatest(DELETE_CHECKLIST, deleteChecklist),
  ]);
}
