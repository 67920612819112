import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { Box } from '@material-ui/core';

import { fetchEquipment } from './actions';

import { Header, Sensors, GeneralInformation } from './components';
import { StepIndex } from './constants';

import useStyles from './styles';

const StepComponents: {
  [key in StepIndex]: (props: any) => JSX.Element;
} = {
  0: GeneralInformation,
  1: Sensors,
};

interface Params {
  id: string;
}

interface Props {
  isEditMode?: boolean;
}

// TODO move out component to lower level
const SaveEquipmentWizard = ({ isEditMode }: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<Params>();

  const [stepIndex, setStepIndex] = useState<StepIndex>(
    StepIndex.GeneralInformation
  );

  const StepComponent = StepComponents[stepIndex];

  const goNext = (): void => {
    setStepIndex((prevStep) => prevStep + 1);
  };

  useEffect(() => {
    if (isEditMode) {
      dispatch(fetchEquipment(params.id));
    }
  }, []);

  return (
    <Box height='100%' display='flex' flexDirection='column'>
      <Header
        isEditMode={isEditMode as boolean}
        currentStepIndex={stepIndex}
        setStepIndex={setStepIndex}
      />
      <div className={classes.body}>
        <StepComponent isEditMode={isEditMode} goNext={goNext} />
      </div>
    </Box>
  );
};

SaveEquipmentWizard.defaultProps = {
  isEditMode: false,
} as Props;

export default SaveEquipmentWizard;
