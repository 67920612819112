import { createAction } from '@reduxjs/toolkit';

import {
  UpdateChecklistPayload,
  UploadChecklistFilePayload,
  UploadChecklistFileSuccessPayload,
  DeleteChecklistFilePayload,
  DeleteChecklistFileSuccessPayload,
  SetManualPagePayload,
  SetFileLoadingIndicatorPayload,
} from './types';

export const CREATE_CHECKLIST = 'CREATE_CHECKLIST';
export const createChecklist = createAction<Checklist>(CREATE_CHECKLIST);

export const GET_CHECKLIST = 'GET_CHECKLIST';
export const getChecklist = createAction<string>(GET_CHECKLIST);

export const GET_CHECKLIST_SUCCESS = 'GET_CHECKLIST_SUCCESS';
export const getChecklistSuccess = createAction<GetChecklistResponse>(
  GET_CHECKLIST_SUCCESS
);

export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST';
export const updateChecklist =
  createAction<UpdateChecklistPayload>(UPDATE_CHECKLIST);

// TODO Create global reducer for this
export const SET_REQUEST_STATUS = 'SET_CHECKLIST_FORM_REQUEST_STATUS';
export const setRequestStatus = createAction<RequestStatus>(SET_REQUEST_STATUS);

export const UPLOAD_CHECKLIST_FILE = 'UPLOAD_CHECKLIST_FILE';
export const uploadChecklistFile = createAction<UploadChecklistFilePayload>(
  UPLOAD_CHECKLIST_FILE
);

export const UPLOAD_CHECKLIST_FILE_SUCCESS = 'UPLOAD_CHECKLIST_FILE_SUCCESS';
export const uploadChecklistFileSuccess =
  createAction<UploadChecklistFileSuccessPayload>(
    UPLOAD_CHECKLIST_FILE_SUCCESS
  );

export const DELETE_CHECKLIST_FILE = 'DELETE_CHECKLIST_FILE';
export const deleteChecklistFile = createAction<DeleteChecklistFilePayload>(
  DELETE_CHECKLIST_FILE
);

export const DELETE_CHECKLIST_FILE_SUCCESS = 'DELETE_CHECKLIST_FILE_SUCCESS';
export const deleteChecklistFileSuccess =
  createAction<DeleteChecklistFileSuccessPayload>(
    DELETE_CHECKLIST_FILE_SUCCESS
  );

export const SET_FILE_LOADING_INDICATOR = 'SET_FILE_LOADING_INDICATOR';
export const setFileLoadingIndicator =
  createAction<SetFileLoadingIndicatorPayload>(SET_FILE_LOADING_INDICATOR);

export const DELETE_STEP_FILES_DATA = 'DELETE_STEP_FILES_DATA';
export const deleteStepFilesData = createAction<number>(DELETE_STEP_FILES_DATA);

export const SET_MANUAL_PAGE = 'SET_MANUAL_PAGE';
export const setManualPage =
  createAction<SetManualPagePayload>(SET_MANUAL_PAGE);

export const RESET_CHECKLIST_FORM_STATE = 'RESET_CHECKLIST_FORM_STATE';
export const resetChecklistFormState = createAction(RESET_CHECKLIST_FORM_STATE);
