import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { resourcesApi, usersApi } from '../../services';
import { setIsLoading } from '../Loader/actions';
import { showNotification } from '../NotificationsManager/actions';
import {
  FETCH_CHECKLISTS_FOR_TICKET,
  FETCH_USERS_FOR_TICKET,
  saveChecklistsForTicket,
  saveCallFromList,
  saveAssignToList,
  CREATE_TICKET,
  toggleTicketModal,
} from './actions';

interface ChecklistsResponseProps {
  checklists: GetChecklistsResponse;
}

interface UsersResponseProps {
  users: TicketUser[];
}

function* fetchChecklistsForTicket(): SagaIterator {
  try {
    const {
      data: { checklists },
    }: Response<ChecklistsResponseProps> = yield call(
      resourcesApi.get,
      '/checklists'
    );

    const data: TicketChecklist[] = checklists.map(({ id, name }) => ({
      key: id,
      value: name,
    }));

    yield put(saveChecklistsForTicket(data));
  } catch (error: any) {
    console.error(error);
  }
}

function* fetchUsersForTicket(): SagaIterator {
  yield put(setIsLoading(true));
  try {
    const {
      data: { users },
    }: Response<UsersResponseProps> = yield call(
      usersApi.get,
      '/service_now/users?name=Engineer 1&name=Engineer 2&name=Supervisor&'
    );
    const callFrom = users.filter(({ name }) => name.trim() === 'Supervisor');
    const assignTo = users.filter(({ name }) => name.trim() !== 'Supervisor');

    yield put(saveCallFromList(callFrom));
    yield put(saveAssignToList(assignTo));
  } catch (error: any) {
    console.error(error);
  } finally {
    yield put(setIsLoading(false));
  }
}

function* submitTicket({ payload }: Action<Ticket>): SagaIterator {
  yield put(setIsLoading(true));
  try {
    const { data }: Response<any> = yield call(resourcesApi.post, '/tickets/', {
      ...payload,
      checklists: [payload.checklists],
      Project: 'MagicLeapFacilities',
      Category: 'Inquiry / Help',
      urgency: 2,
      subcategory: 'none',
    });

    yield put(toggleTicketModal(null));
    yield put(
      showNotification({
        message: `Ticket was created with ID: ${data}`,
        type: 'success',
      })
    );
  } catch (error: any) {
    yield put(
      showNotification({
        message: 'Ticket was not created',
        type: 'error',
      })
    );
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function* ticketSaga(): SagaIterator {
  yield all([
    takeLatest(FETCH_CHECKLISTS_FOR_TICKET, fetchChecklistsForTicket),
    takeLatest(FETCH_USERS_FOR_TICKET, fetchUsersForTicket),
    takeLatest(CREATE_TICKET, submitTicket),
  ]);
}
