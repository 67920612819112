import { useDispatch, useSelector } from 'react-redux';

import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { hideNotification } from './actions';
import { selectNotifications } from './selector';

const NotificationsManager = (): JSX.Element => {
  const dispatch = useDispatch();

  const notifications = useSelector(selectNotifications);

  const handleSnackbarClose = (id: string): void => {
    dispatch(hideNotification(id));
  };

  return (
    <>
      {notifications.map(({ id, message, type }) => (
        <Snackbar
          key={id}
          open
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={(): void => {
            handleSnackbarClose(id);
          }}
        >
          <Alert
            onClose={(): void => {
              handleSnackbarClose(id);
            }}
            severity={type}
          >
            {message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

export default NotificationsManager;
