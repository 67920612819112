import { createSelector } from '@reduxjs/toolkit';

import { State } from './types';

const selectState = (state: RootState): State => state.notificationsManager;

export const selectNotifications = createSelector(
  selectState,
  (state): NotificationsManager.BaseNotification[] => state.notifications
);
