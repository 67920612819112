import { createAction } from '@reduxjs/toolkit';

import { ShowNotificationPayload } from './types';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const showNotification =
  createAction<ShowNotificationPayload>(SHOW_NOTIFICATION);

export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const hideNotification = createAction<string>(HIDE_NOTIFICATION);
