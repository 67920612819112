import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Box, TextField, Typography } from '@material-ui/core';

import clsx from 'clsx';

import { Path } from '../../../../../constants/router';

import PageLeaveWarning, {
  useHandlePageLeave,
} from '../../../../PageLeaveWarning';

import {
  setRequestStatus,
  createEquipment,
  updateEquipment,
  setIsSerialNumberUnique,
} from '../../actions';
import {
  selectIsSerialNumberUnique,
  selectEquipment,
  selectRequestStatus,
} from '../../selectors';
import { getCreateEquipmentPayload } from '../../utils';

import Footer from '../Footer';
import useStyles from './styles';
import { KeyPageElements } from '../../../../../constants/global';

const initialValues: EquipmentGeneralInformation = {
  name: '',
  serial_number: '',
  location: '',
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Mandatory field'),
  serial_number: yup.string().required('Mandatory field'),
  location: yup.string(),
});

interface TextFieldErrors {
  helperText?: string;
  error?: boolean;
}

type SubmitButtonType = 'next' | 'saveEquipment' | null;

interface Props {
  isEditMode: boolean;
  goNext(): void;
}

const GeneralInformation = ({ isEditMode, goNext }: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [
    shouldDisplayWarning,
    setHasUnsavedChanges,
    handleWarningSubmit,
    handleWarningClose,
  ] = useHandlePageLeave({
    extraPageLeaveElements: [KeyPageElements.Sensors],
    shouldHandlePageLeave: isEditMode,
  });

  const equipment = useSelector(selectEquipment);
  const isSerialNumberUnique = useSelector(selectIsSerialNumberUnique);
  const requestStatus = useSelector(selectRequestStatus);

  const handleSubmit = (values: EquipmentGeneralInformation): void => {
    if (isEditMode) {
      const createEquipmentPayload = getCreateEquipmentPayload(values);
      const updatedEquipment = {
        ...equipment,
        ...createEquipmentPayload,
      } as UpdateEquipmentPayload;

      dispatch(updateEquipment(updatedEquipment));
    } else {
      dispatch(createEquipment(values));
    }
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isEditMode && equipment) {
      const { name = '', serial_number = '', location } = equipment;

      const values: EquipmentGeneralInformation = {
        name,
        serial_number,
        location: location?.name || '',
      };

      formik.setValues(values);
    }
  }, [equipment]);

  const getSerialNumberErrors = (): TextFieldErrors => {
    const fieldTouched = formik.touched.serial_number;
    const formikError = fieldTouched && formik.errors.serial_number;
    const isSerialNumberUniqueError = isSerialNumberUnique
      ? ''
      : 'Serial Number must be unique';

    const helperText = formikError || isSerialNumberUniqueError;
    const error = fieldTouched && Boolean(helperText);

    return {
      helperText,
      error,
    };
  };

  const handleSerialNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setIsSerialNumberUnique(true));

    formik.setFieldValue('serial_number', e.target.value);
    setHasUnsavedChanges(true);
  };

  const [clickedSubmitButton, setClickedSubmitButton] =
    useState<SubmitButtonType>(null);

  useEffect(() => {
    if (requestStatus !== 'DONE') return;

    if (clickedSubmitButton === 'next') {
      goNext();
    }

    if (clickedSubmitButton === 'saveEquipment') {
      history.push(Path.Root);
      formik.resetForm();
    }

    dispatch(setRequestStatus('UNSENT'));
  }, [requestStatus, clickedSubmitButton]);

  const isNextButtonDisabled = !(
    formik.values.name && formik.values.serial_number
  );

  const getButtonClickHandler = (buttonType: SubmitButtonType) => (): void => {
    setClickedSubmitButton(buttonType);
    formik.submitForm();
  };

  const buttonName = isEditMode ? 'Save Equipment' : 'Next';

  const handleTextFieldChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    formik.handleChange(e);
    setHasUnsavedChanges(true);
  };

  return (
    <Box display='flex' flexDirection='column'>
      <Typography className={classes.subheading}>
        Please fill the form below and click {buttonName} button to proceed.
        Fields marked with (*) are mandatory.
      </Typography>
      <TextField
        required
        variant='outlined'
        id='name'
        name='name'
        label='Equipment name'
        value={formik.values.name}
        helperText={formik.touched.name && formik.errors.name}
        error={formik.touched.name && Boolean(formik.errors.name)}
        onChange={handleTextFieldChange}
        className={classes.textField}
      />
      <Typography
        className={clsx(classes.text, 'equipmentName')}
        color='secondary'
      >
        This name will be displayed in the equipment listing
      </Typography>
      <TextField
        required
        variant='outlined'
        id='serial_number'
        name='serial_number'
        label='Serial Number'
        disabled={isEditMode}
        value={formik.values.serial_number}
        {...getSerialNumberErrors()}
        onChange={handleSerialNumberChange}
        className={clsx(classes.textField, 'serialNumber')}
      />
      <TextField
        variant='outlined'
        id='location'
        name='location'
        label='Location'
        value={formik.values.location}
        onChange={handleTextFieldChange}
        className={clsx(classes.textField, 'location')}
      />
      <PageLeaveWarning
        open={shouldDisplayWarning}
        onSubmit={handleWarningSubmit}
        onClose={handleWarningClose}
      />
      <Footer
        isEditMode={isEditMode}
        isNextButtonDisabled={isNextButtonDisabled}
        onNextButtonClick={getButtonClickHandler('next')}
        onSaveButtonClick={getButtonClickHandler('saveEquipment')}
      />
    </Box>
  );
};

export default GeneralInformation;
