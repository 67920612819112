export const WEBSOCKET_API_URL =
  'wss://telemetry.dev.mlinvest.org/api/telemetry';

// TODO rename to EQUIPMENT API URL
export const GCP_API_URL = 'https://equipment.dev.mlinvest.org/api';
export const RESOURCES_API_URL = 'https://resources.dev.mlinvest.org/api';

export const USERS_API_URL = 'https://users.dev.mlinvest.org/api';

export enum KeyPageElements {
  GeneralInformation = 'General information',
  Sensors = 'Sensors',
  Equipment = 'Equipment',
  Checklists = 'Checklists',
  People = 'People',
  LogOut = 'Log out',
}
