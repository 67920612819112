import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  peoplePage: {
    marginTop: '16px',
    display: 'grid',
    gap: '16px',
    gridTemplateRows: '232px',
    gridTemplateColumns: 'repeat(auto-fill, 336px)',
  },
  title: {
    fontSize: '34px',
    lineHeight: '40px',
  },
}));
