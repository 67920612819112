export const defaultMediaConfig: MediaConfig = {
  audio: true,
  video: true,
};

export enum SignalingMessageType {
  CALL = '1',
  BUSY = '2',
  ACCEPT = '3',
  END_CALL = '4',
  OFFER_SDP = '5',
  ANSWER_SDP = '6',
  ICE_CANDIDATE = '7',
  PING_PONG = '8',
}

export const ReversedSignalingMessageType: { [key: string]: string } = {
  '1': 'CALL',
  '2': 'BUSY',
  '3': 'ACCEPT',
  '4': 'END_CALL',
  '5': 'OFFER_SDP',
  '6': 'ANSWER_SDP',
  '7': 'ICE_CANDIDATE',
};

export const DebugMessageType = (
  eventType: string,
  message?: string,
  data?: any
): string => {
  console.log('\n');
  console.log('**** debug ****');
  console.log(
    'DebugMessageType',
    eventType,
    ReversedSignalingMessageType[eventType],
    '\n',
    message || '',
    data || ''
  );

  return ReversedSignalingMessageType[eventType];
};

export enum SignalingPlatform {
  WEB = 'WEB',
  XR = 'XR',
}
