import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { resourcesApi } from '../../services';

import { showNotification } from '../NotificationsManager/actions';
import { setIsLoading } from '../Loader/actions';

import {
  CREATE_CHECKLIST,
  GET_CHECKLIST,
  UPDATE_CHECKLIST,
  UPLOAD_CHECKLIST_FILE,
  DELETE_CHECKLIST_FILE,
  setRequestStatus,
  getChecklistSuccess,
  deleteChecklistFileSuccess,
  uploadChecklistFileSuccess,
  setFileLoadingIndicator,
} from './actions';
import {
  UpdateChecklistPayload,
  UploadChecklistFilePayload,
  DeleteChecklistFilePayload,
} from './types';

function* createChecklist({ payload }: Action<Checklist>): SagaIterator {
  yield put(setIsLoading(true));
  try {
    yield put(setRequestStatus('PENDING'));
    yield call(resourcesApi.post, '/checklists', payload);

    yield put(setRequestStatus('DONE'));
  } catch (error) {
    const {
      response: { status },
    } = error as any;

    if (status === 422) {
      yield put(
        showNotification({
          message: 'Checklist name should be unique',
          type: 'error',
        })
      );
    }
  } finally {
    yield put(setIsLoading(false));
    yield put(setRequestStatus('UNSENT'));
  }
}

function* getChecklist({ payload: checklistId }: Action<string>): SagaIterator {
  yield put(setIsLoading(true));
  try {
    const { data: checklist } = yield call(
      resourcesApi.get,
      `/checklists/${checklistId}`
    );

    yield put(getChecklistSuccess(checklist));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(setIsLoading(false));
  }
}

function* updateChecklist({
  payload: { checklistId, ...requestBody },
}: Action<UpdateChecklistPayload>): SagaIterator {
  yield put(setIsLoading(true));
  try {
    yield put(setRequestStatus('PENDING'));

    yield call(resourcesApi.put, `/checklists/${checklistId}`, requestBody);

    yield put(setRequestStatus('DONE'));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(setRequestStatus('UNSENT'));
    yield put(setIsLoading(false));
  }
}

function* uploadChecklistFile({
  payload: { rawFile, category, stepIndex },
}: Action<UploadChecklistFilePayload>): SagaIterator {
  yield put(setFileLoadingIndicator({ category, stepIndex, isLoading: true }));
  try {
    const formData = new FormData();
    formData.append('file', rawFile);

    const {
      data: {
        file_name: serverFileName,
        manual_pages_paths,
        preview_for_video,
      },
    } = yield call(resourcesApi.post, '/checklists/files', formData, {
      params: { category, step: stepIndex },
    });

    yield put(
      uploadChecklistFileSuccess({
        serverFileName,
        category,
        stepIndex,
        manual_pages_paths,
        preview_for_video,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(
      showNotification({
        message: "Can't upload file",
        type: 'error',
      })
    );
  } finally {
    yield put(
      setFileLoadingIndicator({ category, stepIndex, isLoading: false })
    );
  }
}

function* deleteChecklistFile({
  payload: { serverFileName, category, stepIndex },
}: Action<DeleteChecklistFilePayload>): SagaIterator {
  yield put(setFileLoadingIndicator({ category, stepIndex, isLoading: true }));
  try {
    yield call(resourcesApi.delete, '/checklists/files', {
      params: { file_name: serverFileName },
    });

    yield put(deleteChecklistFileSuccess({ category, stepIndex }));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(
      setFileLoadingIndicator({ category, stepIndex, isLoading: false })
    );
  }
}

export default function* (): SagaIterator {
  yield all([
    takeLatest(CREATE_CHECKLIST, createChecklist),
    takeLatest(GET_CHECKLIST, getChecklist),
    takeLatest(UPDATE_CHECKLIST, updateChecklist),
    takeLatest(UPLOAD_CHECKLIST_FILE, uploadChecklistFile),
    takeEvery(DELETE_CHECKLIST_FILE, deleteChecklistFile),
  ]);
}
