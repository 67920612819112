import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import clsx from 'clsx';

import { fetchChecklists } from './actions';
import { selectChecklists } from './selectors';
import useStyles from './styles';
import { formatDate } from './utils';

const EXCLUDE_DELETING_CHECKLIST_ID: string[] = [
  '7a8c9565-3dd7-4200-a9de-0d09373d4374',
  'b4a5c4c1-7802-4907-9702-2911c3807ced',
];

interface Props {
  openChecklistToEdit(checklistId: string): void;
  deleteChecklist(checklistId: string): void;
}

const ChecklistsTable = ({
  openChecklistToEdit,
  deleteChecklist,
}: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchChecklists());
  }, []);

  const checklists = useSelector(selectChecklists);

  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

  const openMenu = (event: any): void => {
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = (): void => {
    setMenuAnchorEl(null);
  };

  const renderRows = (): JSX.Element[] | JSX.Element =>
    checklists.map(({ id, name, created, updated, steps }) => {
      const openMenuButtonId = `open-menu-button-${id}`;

      return (
        <TableRow key={id} className={clsx(classes.checklistsTable, 'row')}>
          <TableCell>{name}</TableCell>
          <TableCell align='right'>{steps.length}</TableCell>
          <TableCell>{formatDate(created)}</TableCell>
          <TableCell>{formatDate(updated)}</TableCell>
          <TableCell>
            <IconButton id={openMenuButtonId} disableRipple onClick={openMenu}>
              <MoreHoriz className={clsx(classes.checklistsTable, 'icon')} />
            </IconButton>
          </TableCell>
          <Menu
            open={menuAnchorEl?.id === openMenuButtonId}
            anchorEl={menuAnchorEl}
            onClose={closeMenu}
          >
            <MenuItem
              onClick={(): void => {
                openChecklistToEdit(id);
              }}
            >
              Edit
            </MenuItem>
            {!EXCLUDE_DELETING_CHECKLIST_ID.includes(id) && (
              <MenuItem
                onClick={(): void => {
                  deleteChecklist(id);
                }}
              >
                Delete
              </MenuItem>
            )}
          </Menu>
        </TableRow>
      );
    });

  return checklists.length ? (
    <TableContainer className={classes.checklistsTable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width='50%'>Name</TableCell>
            <TableCell align='right'>Steps</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Last updated</TableCell>
            <TableCell align='right' />
          </TableRow>
        </TableHead>
        <TableBody>{renderRows()}</TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Box py={4}>
      <Typography variant='h5'>No checklists added</Typography>
    </Box>
  );
};

export default ChecklistsTable;
