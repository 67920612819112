import { makeStyles, Theme } from '@material-ui/core';

const BORDER_COLOR = '#CFD8DC';

export default makeStyles((theme: Theme) => ({
  text: {
    paddingBottom: 40,
  },
  autocomplete: {
    width: 328,
    height: 56,
    background: '#FFF',
    border: 'none',
    '& .MuiSelect-iconFilled': {
      fill: theme.palette.secondary.main,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputLabel-formControl': {
      '&.MuiInputLabel-shrink': {
        ...theme.typography.caption,
        top: 4,
        left: 16,
        color: theme.palette.text.secondary,
        transform: 'none',
      },
    },
    '& .MuiAutocomplete-input': {
      paddingBottom: 0,
    },
  },
  autocompleteOption: {
    height: 56,
  },
  selectLabel: {
    top: '50%',
    left: '16px',
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
  addSensorsButton: {
    width: 136,
    height: 40,
    borderColor: theme.palette.secondary.main,
  },
  deviceTable: {
    marginBottom: 16,
    '&.MuiTableContainer-root': {
      width: 688,
    },
    background: 'transparent',
    boxShadow: 'none',
    borderRadius: 'unset',
    // borderBottom: `1px solid ${BORDER_COLOR}`,
    '& .MuiTableCell-head,.MuiTableCell-body': {
      height: 56,
      padding: 16,
      boxSizing: 'border-box',
      borderColor: BORDER_COLOR,
    },
    '& .MuiTableCell-head': {
      color: theme.palette.text.secondary,
      fontSize: '16px',
      lineHeight: '24px',
      borderBottom: `1px solid ${BORDER_COLOR}`,
    },
  },
  deviceTableBody: {
    background: '#FFF',
  },
  checkbox: {
    padding: 0,
  },
  openMenuButton: {
    padding: 0,
  },
  withoutBorder: {
    border: 'none',
  },
}));
