import { Box, Typography } from '@material-ui/core';
import Sensor from '../Sensor';
import useStyles from './styles';

interface SensorGroupProps {
  sensors: EquipmentList.SensorItem[];
}

const SensorGroup = ({ sensors }: SensorGroupProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box display='flex' className={classes.sensorGroup}>
      {sensors.length ? (
        sensors
          .filter(({ enabled }) => enabled)
          .map((sensor) => <Sensor key={sensor.id} {...sensor} />)
      ) : (
        <Typography className={classes.noSensorsMessage}>
          No sensors added
        </Typography>
      )}
    </Box>
  );
};

export default SensorGroup;
