import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      background: '#ECEFF1',
    },
    container: {
      maxWidth: '512px',
      margin: '0 auto',
    },
    h1: {
      lineHeight: '80px',
      marginBottom: '64px',
      color: '#546E7A',
      fontSize: '60px',
      textAlign: 'center',
    },
    form: {
      padding: '64px 88px',
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.1)',
    },
    formSubmitWrapper: {
      marginTop: '33px',
    },
    title: {
      padding: 0,
      marginBottom: '12px',
      fontSize: '20px',
      lineHeight: '24px',
      maringBottom: '32px',
    },
    password: {
      marginTop: '40px',
    },
    helperText: {
      color: theme.palette.error.main,
    },
  })
);

export default useStyles;
