import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import '@fontsource/roboto';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core';
import { Path } from '../../constants/router';
import {
  Login,
  EquipmentPage,
  ChecklistsPage,
  PeoplePage,
  EquipmentDetailsPage,
  EditEquipmentWizard,
  Loader,
} from '..';
import './normalize.css';
import './styles.css';
import theme from './theme';
import PrivateRoute from './PrivateRoute';

import { auth } from '../Auth/auth';
import { saveUserData } from '../Auth/actions';

import { setIsLoading } from '../Loader/actions';

import { selectIsLoading } from '../Loader/selectors';

import NotificationsManager from '../NotificationsManager';

const App = (): JSX.Element => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsAuthLoading(true);
    dispatch(setIsLoading(true));

    auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthorized(true);
        dispatch(saveUserData({ uid: user.uid, email: user.email as string }));
      } else {
        setIsAuthorized(false);
        dispatch(saveUserData({ uid: '', email: '' }));
      }

      setIsAuthLoading(false);
      dispatch(setIsLoading(false));
    });
  }, []);

  const renderLogin = (): JSX.Element => <Login isAuthorized={isAuthorized} />;

  return (
    <ThemeProvider theme={theme}>
      <Loader isLoading={isLoading} />
      <NotificationsManager />
      {!isAuthLoading && (
        <Router>
          <Switch>
            <PrivateRoute
              exact
              isAuthorized={isAuthorized}
              path={Path.Checklists}
              component={ChecklistsPage}
            />
            <PrivateRoute
              exact
              isAuthorized={isAuthorized}
              path={Path.People}
              component={PeoplePage}
            />
            <PrivateRoute
              exact
              isAuthorized={isAuthorized}
              path={Path.EquipmentDetails}
              component={EquipmentDetailsPage}
            />
            <PrivateRoute
              exact
              isAuthorized={isAuthorized}
              path={Path.Equipment}
              component={EquipmentPage}
            />
            <PrivateRoute
              exact
              isAuthorized={isAuthorized}
              path={Path.EquipmentEdit}
              component={EditEquipmentWizard}
            />
            <Route exact path={Path.Login} render={renderLogin} />
            <Route path='*'>
              <Redirect to={Path.Equipment} />
            </Route>
          </Switch>
        </Router>
      )}
    </ThemeProvider>
  );
};

export default App;
