import { SagaIterator } from 'redux-saga';
import { all, put, call, select, takeLatest } from 'redux-saga/effects';
import usersApi from '../../services/usersApi';
import { selectUid } from '../Auth/selectors';
import {
  setCallingMode,
  saveUserToCall,
  SOCKET_END_CALL,
  SOCKET_USER_IS_BUSY,
  INIT_WEBRTC_SOCKET,
  FETCH_USER_TO_CALL,
  SOCKET_START_CALL,
  setRemoteStream,
} from './actions';
import { RPCHandler, Signaling } from './utils';
import { getIdToken } from '../Auth/auth';
import { SignalingMessageType } from './constants';

function* init(): SagaIterator {
  const myUid = yield select(selectUid);
  const token = yield call(getIdToken);

  Signaling.init(myUid, token);
}

function* startCall({ payload }: Action<User>): SagaIterator {
  yield put(setCallingMode('outcoming'));
  yield put(saveUserToCall(payload));

  Signaling.emit(SignalingMessageType.CALL, { to: payload.uid });
}

function* userIsBusy({ payload }: Action<User>): SagaIterator {
  Signaling.emit(SignalingMessageType.BUSY, { to: payload.uid });
  yield put(setCallingMode('busy'));
  yield put(saveUserToCall(null));
}

function* endCall(): SagaIterator {
  const rpcInstance = RPCHandler.getInstance();
  rpcInstance.endCall();

  yield put(setCallingMode('stopped'));
  yield put(saveUserToCall(null));
  yield put(setRemoteStream(null));
}

function* fetchUserToCall({ payload }: Action<string>): SagaIterator {
  try {
    const {
      data: { users },
    }: Response<PeopleResponse> = yield call(usersApi.get, '/users');

    const userToCall = users.find(({ uid }) => uid === payload);

    if (!userToCall) {
      const unknownUser = {
        createdAt: '',
        disabled: false,
        displayName: 'Unknown',
        email: 'noreply@email.net',
        emailVerified: false,
        lastLoginAt: '',
        lastRefreshAt: '',
        localId: 'unknown',
        passwordHash: '',
        passwordUpdatedAt: 0,
        photoUrl: '',
        platforms: ['web'],
        providerUserInfo: [],
        uid: 'unknown',
        validSince: '',
      };
      yield put(saveUserToCall(unknownUser));
    } else {
      yield put(saveUserToCall(userToCall));
    }
  } catch (e) {
    console.error(e);
  }
}

export default function* peopleSaga(): SagaIterator {
  yield all([
    takeLatest(INIT_WEBRTC_SOCKET, init),
    takeLatest(SOCKET_START_CALL, startCall),
    takeLatest(SOCKET_USER_IS_BUSY, userIsBusy),
    takeLatest(SOCKET_END_CALL, endCall),
    takeLatest(FETCH_USER_TO_CALL, fetchUserToCall),
  ]);
}
