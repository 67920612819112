import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import clsx from 'clsx';
import Header from './Header';
import Drawer from './Drawer';
import VideoCall from '../VideoCall';

import { toggleDrawer as toggleDrawerAction } from './actions';
import { selectIsDrawerOpen } from './selectors';

import useStyles from './styles';

interface Props {
  children: JSX.Element;
  withoutPadding?: boolean;
}

function MainLayout({ children, withoutPadding }: Props): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isDrawerOpen = useSelector(selectIsDrawerOpen);

  const toggleDrawer = (): void => {
    dispatch(toggleDrawerAction());
  };

  return (
    <div className={classes.mainLayout}>
      <Header toggleDrawer={toggleDrawer} />
      <VideoCall />
      <Drawer open={isDrawerOpen} />
      <main
        className={clsx(classes.content, {
          shifted: isDrawerOpen,
          withoutPadding,
        })}
      >
        <Box className={classes.contentInner}>{children}</Box>
      </main>
    </div>
  );
}

MainLayout.defaultProps = {
  withoutPadding: false,
};

export default MainLayout;
