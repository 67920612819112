import { useEffect, useState } from 'react';
import { KeyPageElements } from '../../constants/global';

const BASE_PAGE_LEAVE_ELEMENTS: KeyPageElements[] = [
  KeyPageElements.Equipment,
  KeyPageElements.Checklists,
  KeyPageElements.People,
  KeyPageElements.LogOut,
];

type ReturnValue = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  () => void,
  () => void
];

interface Params {
  excludedPageLeaveElements?: KeyPageElements[];
  extraPageLeaveElements?: KeyPageElements[];
  shouldHandlePageLeave?: boolean;
}

const useHandlePageLeave = ({
  extraPageLeaveElements = [],
  excludedPageLeaveElements = [],
  shouldHandlePageLeave = true,
}: Params): ReturnValue => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [cancelledEvent, setCancelledEvent] = useState<any>(null);
  const [shouldDisplayWarning, setShouldDisplayWarning] =
    useState<boolean>(false);

  const pageLeaveElements = [
    ...BASE_PAGE_LEAVE_ELEMENTS,
    ...extraPageLeaveElements,
  ].filter((item) => !excludedPageLeaveElements?.includes(item));

  const handlePageLeave = (event: any): void => {
    const { innerText } = event.target;

    const condition =
      hasUnsavedChanges && pageLeaveElements.includes(innerText);

    if (condition) {
      event.stopPropagation();
      setCancelledEvent(event);

      setShouldDisplayWarning(true);
    }
  };

  useEffect((): any => {
    if (shouldHandlePageLeave) {
      window.addEventListener('click', handlePageLeave, true);

      return (): void => {
        window.removeEventListener('click', handlePageLeave, true);
      };
    }
    return (): void => {};
  }, [hasUnsavedChanges]);

  useEffect(() => {
    if (!hasUnsavedChanges && cancelledEvent) {
      cancelledEvent.target.dispatchEvent(cancelledEvent);
    }
  }, [hasUnsavedChanges]);

  const handleWarningSubmit = (): void => {
    setHasUnsavedChanges(false);
  };

  const handleWarningClose = (): void => {
    setShouldDisplayWarning(false);

    setCancelledEvent(null);
  };

  return [
    shouldDisplayWarning,
    setHasUnsavedChanges,
    handleWarningSubmit,
    handleWarningClose,
  ];
};

export default useHandlePageLeave;
