import { createReducer } from '@reduxjs/toolkit';
import { TOGGLE_DRAWER } from './actions';

const initialState: MainLayoutState = {
  isDrawerOpen: true,
};

const handlers = {
  [TOGGLE_DRAWER]: (state: MainLayoutState): MainLayoutState => ({
    ...state,
    isDrawerOpen: !state.isDrawerOpen,
  }),
};

export default createReducer<MainLayoutState>(initialState, handlers);
