/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from './actions';
import { State, ShowNotificationPayload } from './types';

const initialState: State = {
  notifications: [],
};

const handlers = {
  [SHOW_NOTIFICATION]: (
    state: State,
    { payload }: Action<ShowNotificationPayload>
  ): void => {
    state.notifications.push({ ...payload, id: uniqueId() });
  },
  [HIDE_NOTIFICATION]: (state: State, { payload }: Action<string>): void => {
    state.notifications = state.notifications.filter(
      ({ id }) => id !== payload
    );
  },
};

export default createReducer<State>(initialState, handlers);
