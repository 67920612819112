import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  initWebRTCSocket,
  setCallingMode,
  fetchUserToCall,
  setRemoteStream,
  socketEndCall,
} from './actions';
import { selectRemoteStreamSrc, selectUserToCall } from './selectors';
import CallingModal from './components/CallingModal';
import VideoModal from './components/VideoModal';
import { defaultMediaConfig, SignalingMessageType } from './constants';
import { RPCHandler, Signaling } from './utils';

function stateCallback(state: string): void {
  if (state === 'failed') {
    console.error('stateCallback: failed');
  }
  if (state === 'complete') {
    console.info('stateCallback: complete');
  }
}

const VideoCall = (): JSX.Element => {
  const dispatch = useDispatch();
  const remoteStreamSrc = useSelector(selectRemoteStreamSrc);

  const mediaCallback = (ev: RTCTrackEvent): void => {
    // ev is our remote track, video or audio
    // adjust UI and add it
    dispatch(setRemoteStream(ev.streams[0]));
  };
  useEffect(() => {
    dispatch(initWebRTCSocket());
  }, []);
  const onSignalingCall = useCallback(({ senderId }: SocketMessage): void => {
    dispatch(fetchUserToCall(senderId));
    dispatch(setCallingMode('incoming'));
  }, []);
  const onSignalingEndCall = (): void => {
    dispatch(socketEndCall());
  };
  const onSignalingBusy = useCallback(({ senderId }: SocketMessage): void => {
    dispatch(fetchUserToCall(senderId));
    dispatch(setCallingMode('busy'));
  }, []);
  const onSignalingAccept = useCallback(
    ({ senderId, receiverId }: SocketMessage): void => {
      dispatch(setCallingMode('stopped'));
      const rpcInstance = RPCHandler.getInstance();
      dispatch(fetchUserToCall(senderId));
      rpcInstance.startRTCCall(
        senderId,
        receiverId,
        mediaCallback,
        stateCallback
      );
    },
    []
  );
  const userToCall: User | null = useSelector(selectUserToCall);

  useEffect(() => {
    Signaling.on(SignalingMessageType.CALL, onSignalingCall);
    Signaling.on(SignalingMessageType.END_CALL, onSignalingEndCall);
    Signaling.on(SignalingMessageType.BUSY, onSignalingBusy);
    Signaling.on(SignalingMessageType.ACCEPT, onSignalingAccept);

    return (): void => {
      Signaling.killAll();
    };
  }, []);

  return (
    <>
      {userToCall && <CallingModal user={userToCall} />}
      {remoteStreamSrc && (
        <VideoModal config={defaultMediaConfig} remoteSrc={remoteStreamSrc} />
      )}
    </>
  );
};

export default VideoCall;
