import { createReducer } from '@reduxjs/toolkit';

import {
  FETCH_EQUIPMENT_SUCCESS,
  SET_REQUEST_STATUS,
  SET_IS_SERIAL_NUMBER_UNIQUE,
  CREATE_EQUIPMENT_SUCCESS,
  FETCH_DEVICES_SUCCESS,
} from './actions';

type State = EquipmentState.Wizard;
type Equipment = EquipmentList.EquipmentItem;

const initialState: State = {
  isSerialNumberUnique: true,
  requestStatus: 'UNSENT',
  equipment: null,
  devices: {
    groups: [],
    errors: [],
  },
};

const handlers = {
  [FETCH_EQUIPMENT_SUCCESS]: (
    state: State,
    { payload: equipment }: Action<Equipment>
  ): State => ({
    ...state,
    equipment,
  }),
  [SET_REQUEST_STATUS]: (
    state: State,
    { payload }: Action<RequestStatus>
  ): State => ({
    ...state,
    requestStatus: payload,
  }),
  [SET_IS_SERIAL_NUMBER_UNIQUE]: (
    state: State,
    { payload }: Action<boolean>
  ): State => ({
    ...state,
    isSerialNumberUnique: payload,
  }),
  [CREATE_EQUIPMENT_SUCCESS]: (
    state: State,
    { payload }: Action<Partial<Equipment>>
  ): State => ({
    ...state,
    equipment: payload,
  }),
  [FETCH_DEVICES_SUCCESS]: (
    state: State,
    { payload }: Action<DevicesResponse>
  ): State => ({
    ...state,
    devices: payload,
  }),
};

export default createReducer<State>(initialState, handlers);
