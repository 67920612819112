import { createReducer } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';
import {
  SAVE_EQUIPMENT_LIST,
  SET_EQUIPMENT_LIST_ERROR,
  SET_DEVICE_SOCKET_DATA,
} from './actions';

type State = EquipmentState.List;
type Equipment = EquipmentList.EquipmentItem;
type IotDevice = EquipmentList.IotDevice;
type Sensor = EquipmentList.SensorItem;

const initialState: State = {
  equipmentList: [],
  equipmentListError: null,
};

const setDeviceSocketDataHandler = (
  state: State,
  { payload }: Action<DeviceSocketData>
): State => {
  const { device_id, data } = payload;

  const updateSensor = (sensor: Sensor): Sensor => {
    const { attribute } = sensor;
    const hasNoValue = isUndefined(
      payload[attribute as keyof DeviceSocketData]
    );
    const valueFromSocket = hasNoValue
      ? data
      : payload[attribute as keyof DeviceSocketData];

    return {
      ...sensor,
      currentValue: valueFromSocket.toString(),
    };
  };

  const updateIotDevice = (iotDevice: IotDevice): IotDevice => {
    if (iotDevice.device_id !== device_id) return iotDevice;

    return {
      ...iotDevice,
      sensors: iotDevice.sensors.map(updateSensor),
    };
  };

  const updateEquipment = ({ iot_device, ...rest }: Equipment): Equipment => ({
    ...rest,
    iot_device: iot_device.map(updateIotDevice),
  });

  const equipmentList = state.equipmentList.map(updateEquipment);

  return {
    ...state,
    equipmentList,
  };
};

const handlers = {
  [SAVE_EQUIPMENT_LIST]: (
    state: State,
    { payload }: Action<Equipment[]>
  ): State => ({
    ...state,
    equipmentList: payload,
  }),
  [SET_EQUIPMENT_LIST_ERROR]: (
    state: State,
    { payload }: Action<any>
  ): State => ({
    ...state,
    equipmentListError: payload,
  }),
  [SET_DEVICE_SOCKET_DATA]: setDeviceSocketDataHandler,
};

export default createReducer<State>(initialState, handlers);
