import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  addChecklistButton: {
    height: '40px',
    padding: '12px 16px 12px 13px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderRadius: 4,
  },
  checklistsTable: {
    '& .MuiTableCell-head': {
      color: theme.palette.secondary.main,
      fontSize: '16px',
      lineHeight: '24px',
    },
    '&.row': {
      backgroundColor: '#FFF',
    },
    '& .MuiTableCell-body': {
      height: 55,
      padding: '0 16px',
      borderColor: '#CFD8DC',
    },
    '&.icon': {
      fill: theme.palette.secondary.main,
    },
  },
}));
