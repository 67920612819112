import { createAction } from '@reduxjs/toolkit';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const SET_LOGIN_USER_ERROR = 'SET_LOGIN_USER_ERROR';
export const SAVE_USER_DATA = 'SAVE_USER_DATA';

export const loginUser = createAction<UserCredentials>(LOGIN_USER);
export const logoutUser = createAction<string | undefined>(LOGOUT_USER);
export const setLoginUserError = createAction<object>(SET_LOGIN_USER_ERROR);
export const saveUserData = createAction<UserData>(SAVE_USER_DATA);
