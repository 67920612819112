import { TableCell, Checkbox } from '@material-ui/core';
import useStyles from '../styles';

interface Props {
  enabled: boolean;
  handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>): void;
  id: string;
}

export const CheckboxCell = ({
  enabled,
  handleCheckboxChange,
  id,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <TableCell>
      <Checkbox
        color='primary'
        id={id}
        onChange={handleCheckboxChange}
        checked={enabled}
        className={classes.checkbox}
      />
    </TableCell>
  );
};
