import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  callingModeModalRoot: {
    fontSize: '24px',
    lineHeight: '48px',
    fontWeight: 600,
    position: 'absolute',
    top: 80,
    zIndex: 1000,
    right: 50,
    width: 330,
    padding: 16,
    '&.busy': {
      background: '#f6d5d5',
    },
  },
  userName: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 500,
    margin: '0',
  },
  calling: {
    fontStyle: 'italic',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    '.busy &': {
      fontStyle: 'italic',
      fontWeight: 700,
      color: '#D32F2F',
    },
  },
  button: {
    width: 90,
    height: 40,
    color: '#FFFFFF',
  },
  accept: {
    backgroundColor: '#2E7D32',
    border: '1px solid #2E7D32',
    '&:hover': {
      color: '#2E7D32',
    },
  },
  reject: {
    backgroundColor: theme.palette.error.main,
    border: '1px solid #D32F2F',
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
}));
