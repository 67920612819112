import { createAction } from '@reduxjs/toolkit';

export const TOGGLE_TICKET_MODAL = 'TOGGLE_TICKET_MODAL';
export const FETCH_CHECKLISTS_FOR_TICKET = 'FETCH_CHECKLISTS_FOR_TICKET';
export const SAVE_CHECKLISTS_FOR_TICKET = 'SAVE_CHECKLISTS_FOR_TICKET';
export const FETCH_USERS_FOR_TICKET = 'FETCH_USERS_FOR_TICKET';
export const SAVE_ASSIGN_TO_LIST = 'SAVE_ASSIGN_TO_LIST';
export const SAVE_CALL_FROM_LIST = 'SAVE_CALL_FROM_LIST';
export const CREATE_TICKET = 'CREATE_TICKET';
export const SET_IS_TICKET_LOADING = 'SET_IS_TICKET_LOADING';

export const toggleTicketModal = createAction<TicketData | null>(
  TOGGLE_TICKET_MODAL
);

export const fetchChecklistsForTicket = createAction(
  FETCH_CHECKLISTS_FOR_TICKET
);

export const saveChecklistsForTicket = createAction<TicketChecklist[]>(
  SAVE_CHECKLISTS_FOR_TICKET
);

export const fetchUsersForTicket = createAction(FETCH_USERS_FOR_TICKET);

export const saveCallFromList = createAction<TicketUser[]>(SAVE_CALL_FROM_LIST);
export const saveAssignToList = createAction<TicketUser[]>(SAVE_ASSIGN_TO_LIST);

export const createTicket = createAction<Ticket | null>(CREATE_TICKET);

export const setTIcketIsLoading = createAction<boolean>(SET_IS_TICKET_LOADING);
