import { createReducer } from '@reduxjs/toolkit';
import { SET_IS_LOADING } from './actions';

const initialState: LoaderState = {
  isLoading: false,
};

export default createReducer<LoaderState>(initialState, {
  [SET_IS_LOADING]: (state, { payload = false }): LoaderState => ({
    ...state,
    isLoading: payload,
  }),
});
