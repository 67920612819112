import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976D2',
    },
    secondary: {
      dark: '#424242',
      main: '#546E7A',
      light: '#9E9E9E',
    },
    error: {
      main: '#D32F2F',
    },
    text: {
      primary: '#424242',
      secondary: '#546E7A',
    },
  },
  typography: {
    h2: {
      fontSize: '34px',
      lineHeight: '40px',
    },
    h5: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    body1: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    caption: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        '&.MuiAutocomplete-paper': {
          marginTop: '8px',
          borderRadius: 'unset',
          '& .MuiAutocomplete-listbox': {
            padding: 0,
          },
          '& .MuiAutocomplete-option': {
            height: 56,
            padding: '16px 24px 16px 16px',
            borderBottom: '1px solid #CFD8DC',
          },
        },
      },
    },
  },
});

export default theme;
