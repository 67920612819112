import { Box, TextField, Typography } from '@material-ui/core';

import { FormikProps } from 'formik';
import { KeyPageElements } from '../../../constants/global';

import { FormikValues } from '../types';
import useStyles from './styles';

interface Props {
  isEditMode: boolean;
  formik: FormikProps<FormikValues>;
}

const GeneralInformation = ({ isEditMode, formik }: Props): JSX.Element => {
  const classes = useStyles();

  const {
    values: { name },
    errors,
    handleChange,
  } = formik;

  return (
    <Box pt={6} pb={7} paddingX={9}>
      <Typography variant='h2'>
        {isEditMode ? 'Edit checklist' : 'Add checklist'}
      </Typography>
      <Box mt={5}>
        <Typography variant='h5'>
          {KeyPageElements.GeneralInformation}
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography>Fields marked with (*) are mandatory.</Typography>
      </Box>
      <TextField
        required
        autoComplete='off'
        variant='outlined'
        label='Checklist name'
        name='name'
        value={name}
        helperText={errors.name}
        error={Boolean(errors.name)}
        onChange={handleChange}
        className={classes.textField}
      />
      <Box mt={1} pl={2}>
        <Typography variant='caption' color='secondary'>
          This name will be displayed in the checklist listing
        </Typography>
      </Box>
    </Box>
  );
};

export default GeneralInformation;
