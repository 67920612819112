import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { setIsLoading } from '../Loader/actions';
import { FETCH_USERS, saveUsers } from './actions';
import usersApi from '../../services/usersApi';
import { selectUid } from '../Auth/selectors';

export function* initUsersSaga(): SagaIterator {
  yield put(setIsLoading(true));
  const myUid = yield select(selectUid);
  try {
    const {
      data: { users },
    }: Response<PeopleResponse> = yield call(usersApi.get, '/users');

    yield put(saveUsers(users.filter(({ uid }) => uid !== myUid)));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function* peopleSaga(): SagaIterator {
  yield all([takeLatest(FETCH_USERS, initUsersSaga)]);
}
