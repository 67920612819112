import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { openDeviceSocket, closeDeviceSocket } from './actions';

export const useManageIotDevicesSocket = (
  iotDevices: EquipmentList.IotDevice[]
): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    iotDevices.forEach(({ device_id }) => {
      dispatch(openDeviceSocket(device_id));
    });

    return (): void => {
      iotDevices.forEach(({ device_id }) => {
        dispatch(closeDeviceSocket(device_id));
      });
    };
  }, []);
};
