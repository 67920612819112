import { useEffect, createRef, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Box, Button, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';

import useStyles from './styles';
import Equipment from './components/Equipment';

import { getEquipmentList } from './actions';
import { selectEquipmentItems } from './selectors';
import { TOTAL_ITEMS_ON_PAGE } from './constants';

interface Props {
  openWizard(): void;
}

const List = ({ openWizard }: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const topOfPage = createRef<HTMLDivElement>();
  const equipmentItems = useSelector(selectEquipmentItems);
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleEquipmentItems, setVisibleEquipmentItems] = useState<
    EquipmentList.EquipmentItem[]
  >([]);

  useEffect(() => {
    dispatch(getEquipmentList());
  }, []);

  useEffect(() => {
    if (!isEmpty(equipmentItems)) {
      const firstItemIndex = (currentPage - 1) * TOTAL_ITEMS_ON_PAGE;
      const lastItemIndex = currentPage * TOTAL_ITEMS_ON_PAGE;

      setVisibleEquipmentItems(
        equipmentItems.slice(firstItemIndex, lastItemIndex)
      );
    }
  }, [equipmentItems, currentPage]);

  const onPaginationChange = (e: ChangeEvent<any>, page: number): void => {
    setCurrentPage(page);
    topOfPage.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const totalEquipmentList = equipmentItems.length;

  return (
    <Box>
      <div ref={topOfPage} />
      <Box display='flex' justifyContent='space-between'>
        <Typography className={classes.title}>Equipment list</Typography>
        <Button
          variant='outlined'
          className={classes.addEquipmentButton}
          onClick={openWizard}
        >
          <AddIcon />
          Add equipment
        </Button>
      </Box>
      {visibleEquipmentItems.map((equipment) => (
        <Equipment key={equipment.id} {...equipment} />
      ))}
      {totalEquipmentList > TOTAL_ITEMS_ON_PAGE && (
        <Pagination
          className={classes.pagination}
          count={Math.ceil(equipmentItems.length / TOTAL_ITEMS_ON_PAGE)}
          onChange={onPaginationChange}
          shape='rounded'
        />
      )}
    </Box>
  );
};

export default List;
