/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { set } from 'lodash';

import {
  SET_REQUEST_STATUS,
  GET_CHECKLIST_SUCCESS,
  UPLOAD_CHECKLIST_FILE_SUCCESS,
  DELETE_CHECKLIST_FILE_SUCCESS,
  DELETE_STEP_FILES_DATA,
  SET_FILE_LOADING_INDICATOR,
  RESET_CHECKLIST_FORM_STATE,
  SET_MANUAL_PAGE,
} from './actions';
import { DEFAULT_MANUAL_PAGE } from './constants';
import {
  UploadChecklistFileSuccessPayload,
  DeleteChecklistFileSuccessPayload,
  SetManualPagePayload,
  SetFileLoadingIndicatorPayload,
} from './types';

type State = ChecklistForm.State;

const initialState: State = {
  requestStatus: 'UNSENT',
  checklistForEditing: null,
  uploadingFilesByStepIndex: {},
  filesByStepIndex: {},
};

const handlers = {
  [SET_REQUEST_STATUS]: (
    state: State,
    { payload: requestStatus }: Action<RequestStatus>
  ): void => {
    state.requestStatus = requestStatus;
  },
  [GET_CHECKLIST_SUCCESS]: (
    state: State,
    { payload: checklistForEditing }: Action<GetChecklistResponse>
  ): void => {
    state.checklistForEditing = checklistForEditing;

    checklistForEditing.steps.forEach(
      (
        {
          animation = null,
          manual = null,
          manual_page,
          manual_pages_paths = [],
          preview_for_video = null,
          video = null,
        },
        index
      ) => {
        state.filesByStepIndex[index] = {
          ...state.filesByStepIndex[index],
          animation,
          manual,
          manual_page: manual_page || DEFAULT_MANUAL_PAGE,
          manual_pages_paths,
          preview_for_video,
          video,
        };
      }
    );
  },
  [UPLOAD_CHECKLIST_FILE_SUCCESS]: (
    state: State,
    {
      payload: {
        category,
        serverFileName,
        stepIndex,
        manual_pages_paths,
        preview_for_video,
      },
    }: Action<UploadChecklistFileSuccessPayload>
  ): void => {
    set(state.filesByStepIndex, `${stepIndex}.${category}`, serverFileName);

    if (category === 'manual') {
      set(
        state.filesByStepIndex,
        `${stepIndex}.manual_pages_paths`,
        manual_pages_paths
      );
      set(
        state.filesByStepIndex,
        `${stepIndex}.manual_page`,
        DEFAULT_MANUAL_PAGE
      );
    }

    if (category === 'video') {
      set(
        state.filesByStepIndex,
        `${stepIndex}.preview_for_video`,
        preview_for_video
      );
    }
  },
  [DELETE_CHECKLIST_FILE_SUCCESS]: (
    state: State,
    {
      payload: { category, stepIndex },
    }: Action<DeleteChecklistFileSuccessPayload>
  ): void => {
    const filesData = state.filesByStepIndex[stepIndex];

    filesData[category] = null;

    if (category === 'manual') {
      filesData.manual_pages_paths = [];
    }

    if (category === 'video') {
      filesData.preview_for_video = null;
    }
  },
  [SET_FILE_LOADING_INDICATOR]: (
    state: State,
    {
      payload: { category, stepIndex, isLoading },
    }: Action<SetFileLoadingIndicatorPayload>
  ): void => {
    set(state.uploadingFilesByStepIndex, `${stepIndex}.${category}`, isLoading);
  },
  [DELETE_STEP_FILES_DATA]: (
    state: State,
    { payload: stepIndex }: Action<number>
  ): void => {
    const updatedFilesEntries = Object.entries(state.filesByStepIndex)
      .filter(([key]) => Number(key) !== stepIndex)
      .map(([, filesData], index) => [index, filesData]);
    const filesByStepIndex = Object.fromEntries(updatedFilesEntries);

    state.filesByStepIndex = filesByStepIndex;
  },
  [SET_MANUAL_PAGE]: (
    state: State,
    { payload: { manualPage, stepIndex } }: Action<SetManualPagePayload>
  ): void => {
    set(state.filesByStepIndex, `${stepIndex}.manual_page`, manualPage);
  },
  [RESET_CHECKLIST_FORM_STATE]: (): State => initialState,
};

export default createReducer<State>(initialState, handlers);
