import { useState, MouseEvent } from 'react';
import Box from '@material-ui/core/Box';
import { IconButton, MenuItem, Typography, Popover } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './styles';
import { selectEmail } from '../Auth/selectors';
import { logoutUser } from '../Auth/actions';
import { KeyPageElements } from '../../constants/global';

interface Props {
  toggleDrawer(): void;
}

function Header({ toggleDrawer }: Props): JSX.Element {
  const classes = useStyles();
  const email = useSelector(selectEmail);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const toggleAccountMenu = (e: MouseEvent<HTMLElement>): void => {
    setAnchorEl((prevState) => (prevState ? null : e.currentTarget));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'account-popover' : undefined;

  const onLogout = (): void => {
    dispatch(logoutUser());
  };

  return (
    <Box className={classes.header}>
      <IconButton
        disableRipple
        onClick={toggleDrawer}
        color='inherit'
        className={classes.openDrawerButton}
      >
        <MenuIcon />
      </IconButton>
      <Box className={classes.headerInner}>
        <Box>
          <Typography>XR App Web portal</Typography>
        </Box>
        <Box className={classes.account}>
          <Typography align='right'>{email}</Typography>
          <Box onClick={toggleAccountMenu}>
            <KeyboardArrowDownRoundedIcon />
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={toggleAccountMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem key='4' onClick={onLogout}>
              {KeyPageElements.LogOut}
            </MenuItem>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
