import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { Path } from '../../constants/router';

interface PrivateRouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
  isAuthorized: boolean;
  exact?: boolean;
  path: Path;
}

const PrivateRoute = ({
  component: Component,
  exact,
  isAuthorized,
  ...rest
}: PrivateRouteProps): JSX.Element => (
  <Route
    exact={exact}
    {...rest}
    render={(props): JSX.Element =>
      isAuthorized ? <Component {...props} /> : <Redirect to={Path.Login} />
    }
  />
);

PrivateRoute.defaultProps = {
  exact: true,
};

export default PrivateRoute;
