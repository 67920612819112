import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest, select, delay } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import {
  LOGIN_USER,
  setLoginUserError,
  saveUserData,
  LOGOUT_USER,
} from './actions';
import { signIn, logout } from './auth';
import { setIsLoading } from '../Loader/actions';
import usersApi from '../../services/usersApi';
import { selectUid } from './selectors';
import { fetchUsers } from '../PeoplePage/actions';

export function* loginUser(action: Action<UserCredentials>): SagaIterator {
  const { payload = { email: '', password: '' } } = action;
  yield put(setIsLoading(true));

  try {
    const response = yield call(signIn, payload);
    const { user } = response;
    const { email, uid } = user;

    yield call(usersApi.post, '/login', { platform: 'web', uid });

    yield put(saveUserData({ uid, email }));
    yield put(setLoginUserError({ error: null }));
  } catch (error) {
    yield put(setLoginUserError({ error: 'Login User: Error happend' }));
  } finally {
    yield put(setIsLoading(false));
  }
}
export function* logoutUser({
  payload,
}: Action<string | undefined>): SagaIterator {
  yield put(setIsLoading(true));

  try {
    if (typeof payload === 'string' && !isEmpty(payload)) {
      // Logout concrete engineer via People Page -> Logout
      yield call(usersApi.post, '/logout', { uid: payload });
      yield put(fetchUsers());
    } else {
      // Logout supervisor via Header -> Logout
      const uid = yield select(selectUid);
      yield call(usersApi.post, '/logout', { platform: 'web', uid });
      yield call(logout);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield delay(500);
    yield put(setIsLoading(false));
  }
}

export default function* authSaga(): SagaIterator {
  yield all([takeLatest(LOGIN_USER, loginUser)]);
  yield all([takeLatest(LOGOUT_USER, logoutUser)]);
}
