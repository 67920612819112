import { createReducer } from '@reduxjs/toolkit';
import { defaultMediaConfig } from './constants';
import {
  SAVE_USER_TO_CALL,
  SHOW_VIDEO_MODAL,
  SET_MEDIA_CONFIG,
  SET_CALL_MODE,
  SET_REMOTE_STREAM,
} from './actions';

const initialState: VideoCallState = {
  userToCall: null,
  isVideoModalShown: false,
  mediaConfig: defaultMediaConfig,
  callingMode: 'stopped',
  remoteStreamSrc: null,
};

const handlers = {
  [SAVE_USER_TO_CALL]: (
    state: VideoCallState,
    { payload }: Action<User | null>
  ): VideoCallState => ({
    ...state,
    userToCall: payload,
  }),
  [SHOW_VIDEO_MODAL]: (
    state: VideoCallState,
    { payload }: Action<boolean>
  ): VideoCallState => ({
    ...state,
    isVideoModalShown: payload,
  }),
  [SET_MEDIA_CONFIG]: (
    state: VideoCallState,
    { payload }: Action<MediaConfig>
  ): VideoCallState => ({
    ...state,
    mediaConfig: payload,
  }),
  [SET_CALL_MODE]: (
    state: VideoCallState,
    { payload }: Action<CallingMode>
  ): VideoCallState => ({
    ...state,
    callingMode: payload,
  }),
  [SET_REMOTE_STREAM]: (
    state: VideoCallState,
    { payload }: Action<MediaStream | null>
  ): VideoCallState => ({
    ...state,
    remoteStreamSrc: payload,
  }),
};

export default createReducer<VideoCallState>(initialState, handlers);
