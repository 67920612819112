import { Typography, Paper, Button } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../Auth/actions';
import { socketStartCall } from '../../../VideoCall/actions';
import useStyles from './styles';

const UserCard = (props: User): JSX.Element => {
  const { email, displayName, photoUrl, uid } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const userName = isEmpty(displayName) ? email : displayName;

  const onInitiateCall = (): void => {
    dispatch(socketStartCall(props));
  };

  const onLogout = (): void => {
    dispatch(logoutUser(uid));
  };

  return (
    <Paper className={classes.userCard}>
      <img src={photoUrl} alt={userName} width={80} height={80} />
      <Typography className={classes.userName}>{userName}</Typography>
      <div className={classes.buttonWrapper}>
        <Button variant='contained' color='primary' onClick={onInitiateCall}>
          Call
        </Button>
        <Button variant='outlined' color='secondary' onClick={onLogout}>
          Log out
        </Button>
      </div>
    </Paper>
  );
};

export default UserCard;
