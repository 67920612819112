import { combineReducers, ReducersMapObject } from '@reduxjs/toolkit';
import _ from 'lodash';

import mainLayout from '../components/MainLayout/reducer';
import auth from '../components/Auth/reducer';
import loader from '../components/Loader/reducer';
import equipment from '../components/EquipmentPage/reducers';
import people from '../components/PeoplePage/reducer';
import videoCall from '../components/VideoCall/reducer';
import checklistForm from '../components/ChecklistForm/reducer';
import checklistsPage from '../components/ChecklistsPage/reducer';
import ticket from '../components/Ticket/reducer';
import notificationsManager from '../components/NotificationsManager/reducer';

const sortedReducers = _({
  mainLayout,
  auth,
  loader,
  equipment,
  people,
  videoCall,
  checklistForm,
  checklistsPage,
  ticket,
  notificationsManager,
})
  .toPairs()
  .sortBy(0)
  .fromPairs()
  .value() as unknown;

const rootReducer = combineReducers<RootState>(
  sortedReducers as ReducersMapObject<RootState>
);

export default rootReducer;
