import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import clsx from 'clsx';
import { useFormik } from 'formik';
import { isNull, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchChecklistsForTicket,
  toggleTicketModal,
  fetchUsersForTicket,
  createTicket,
} from './actions';
import {
  selectTicketData,
  selectChecklistsForTicket,
  selectCallFromList,
  selectAssignToList,
  selectTicketLoading,
} from './selectors';

import useStyles from './styles';
import { Loader } from '../Loader';
import PageLeaveWarning from '../PageLeaveWarning';

// We hardcode Urgency as medium (2), and update just Imact field.
// Because in Service Now priority sets as combination of Impact and Urgency.

const impactList = {
  High: 1,
  Medium: 2,
  Low: 3,
};

const TicketModal = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const ticketData = useSelector(selectTicketData);
  const checklists = useSelector(selectChecklistsForTicket);
  const callFromList = useSelector(selectCallFromList);
  const assignToList = useSelector(selectAssignToList);
  const isLoading = useSelector(selectTicketLoading);
  const [shouldDisplayWarning, setShouldDisplayWarning] = useState(false);

  const handleSubmit = (ticket: Ticket): void => {
    dispatch(createTicket(ticket));
  };

  const formik = useFormik<Ticket>({
    initialValues: {
      caller_id: '',
      assigned_to: '',
      impact: 2,
      short_description: '',
      description: '',
      checklists: [],
      u_facilitydevice: '',
      u_facilityid: '',
    },
    onSubmit: (ticket: Ticket) => {
      handleSubmit(ticket);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (ticketData && isEmpty(checklists)) {
      dispatch(fetchChecklistsForTicket());
    }
  }, [ticketData]);

  useEffect(() => {
    if (ticketData && isEmpty(assignToList) && isEmpty(callFromList)) {
      dispatch(fetchUsersForTicket());
    }
  }, [ticketData]);

  const isSaveButtonDisabled =
    isEmpty(formik.values.assigned_to) ||
    isEmpty(formik.values.caller_id) ||
    isEmpty(formik.values.short_description);

  const onClose = (): void => {
    if (formik.dirty) {
      setShouldDisplayWarning(true);
    } else {
      dispatch(toggleTicketModal(null));
    }
  };

  if (!ticketData) {
    return null;
  }

  const { name, serialNumber } = ticketData;

  formik.values.u_facilitydevice = name;
  formik.values.u_facilityid = serialNumber;

  const handleWarningClose = (): void => {
    setShouldDisplayWarning(false);
  };

  const handleWarningSubmit = (): void => {
    dispatch(toggleTicketModal(null));
    formik.resetForm();
    handleWarningClose();
  };

  return (
    <Dialog
      open={!isNull(ticketData)}
      onClose={onClose}
      className={classes.createTicketModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography component='span' variant='h6'>
            Create ticket for {`${name} #${serialNumber}`}
          </Typography>
          <IconButton onClick={onClose} className={classes.closeButton}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : (
          <Box id='alert-dialog-description'>
            <Box>
              <FormControl variant='outlined' className={classes.selectInput}>
                <InputLabel id='impact'>Priority</InputLabel>
                <Select
                  labelId='impact-label'
                  name='impact'
                  value={formik.values.impact}
                  onChange={formik.handleChange}
                  label='Priority'
                >
                  {Object.entries(impactList).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                required
                variant='outlined'
                className={clsx(
                  classes.assignInputWrapper,
                  classes.selectInput
                )}
              >
                <InputLabel id='assigned_to'>Assign to</InputLabel>
                <Select
                  labelId='assigned_to-label'
                  name='assigned_to'
                  value={formik.values.assigned_to}
                  onChange={formik.handleChange}
                  label='Assign to'
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {assignToList.map((user) => (
                    <MenuItem key={user.sys_id} value={user.sys_id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant='outlined'
                className={classes.selectInput}
                required
              >
                <InputLabel id='caller'>Caller Id</InputLabel>
                <Select
                  labelId='caller-label'
                  name='caller_id'
                  value={formik.values.caller_id}
                  onChange={formik.handleChange}
                  label='Caller Id'
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {callFromList.map((user) => (
                    <MenuItem key={user.sys_id} value={user.sys_id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <TextField
              required
              autoComplete='off'
              variant='outlined'
              label='Short description'
              name='short_description'
              value={formik.values.short_description}
              onChange={formik.handleChange}
              className={classes.shortDescription}
              maxRows={160}
              minRows={1}
            />
            <TextField
              autoComplete='off'
              variant='outlined'
              label='Description'
              name='description'
              value={formik.values.description}
              onChange={formik.handleChange}
              className={classes.longDescription}
            />
            <FormControl variant='outlined' className={classes.selectInput}>
              <InputLabel id='checklists'>Checklist</InputLabel>
              <Select
                labelId='checklists-label'
                name='checklists'
                value={formik.values.checklists}
                onChange={formik.handleChange}
                label='Checklist'
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                {checklists.map(({ key, value }) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button
          variant='contained'
          color='primary'
          disabled={isSaveButtonDisabled}
          onClick={formik.submitForm}
        >
          Create ticket
        </Button>
      </DialogActions>
      <PageLeaveWarning
        open={shouldDisplayWarning}
        onSubmit={handleWarningSubmit}
        onClose={handleWarningClose}
      />
    </Dialog>
  );
};

export default TicketModal;
