import { useState } from 'react';
import MainLayout from '../MainLayout';
import TicketModal from '../Ticket';

import List from './List';
import Wizard from './Wizard';

const EquipmentPage = (): JSX.Element => {
  const [isWizardOpen, setIsWizardOpen] = useState<boolean>(false);

  const openWizard = (): void => {
    setIsWizardOpen(true);
  };

  return (
    <MainLayout withoutPadding={isWizardOpen}>
      {isWizardOpen ? (
        <Wizard />
      ) : (
        <>
          <List openWizard={openWizard} />
          <TicketModal />
        </>
      )}
    </MainLayout>
  );
};

export default EquipmentPage;
