import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  title: {
    fontSize: '34px',
    lineHeight: '40px',
  },
  addEquipmentButton: {
    height: '40px',
    padding: '12px 16px 12px 13px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderRadius: 4,
  },
  pagination: {
    paddingTop: '32px',
    '& .MuiPagination-ul': {
      justifyContent: 'center',
    },
  },
}));
