import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  sensorGroup: {
    marginTop: '16px',
    display: 'grid',
    gridTemplateRows: '72px',
    gap: '8px',
    gridTemplateColumns: 'repeat(auto-fill, 120px)',
  },
  noSensorsMessage: {
    gridColumn: '1 / 7',
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
}));
