import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  sensor: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: '#EEEEEE',
    padding: '8px',
    borderRadius: '4px',
    '&:last-child': {
      marginRight: '0',
    },
  },
  name: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    '.notAvailable &': {
      color: '#9E9E9E',
    },
  },
  value: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    '.notAvailable &': {
      color: '#9E9E9E',
    },
  },
  unit: {
    '.notAvailable &': {
      color: '#9E9E9E',
    },
  },
}));
