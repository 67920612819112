import { Typography } from '@material-ui/core';
import useStyles from './styles';

const NoUsersOnline = (): JSX.Element => {
  const classes = useStyles();

  return <Typography className={classes.noUsers}>No users online</Typography>;
};

export default NoUsersOnline;
