import { useSelector } from 'react-redux';

import { Box, Typography } from '@material-ui/core';

import { STEPS, StepType, StepIndex } from '../../constants';
import { selectEquipment } from '../../selectors';

import HeaderStep from './HeaderStep';

import useStyles from './styles';

interface Props {
  isEditMode: boolean;
  currentStepIndex: number;
  setStepIndex(index: StepIndex): void;
}

const Header = ({
  isEditMode,
  currentStepIndex,
  setStepIndex,
}: Props): JSX.Element => {
  const classes = useStyles();
  const equipment = useSelector(selectEquipment);

  const equipmentName = equipment?.name;

  const title = isEditMode
    ? `Edit equipment ${equipmentName ? ` - ${equipmentName}` : ''}`
    : 'Add equipment';

  const getType = (index: number): StepType => {
    if (index === currentStepIndex) return 'active';

    if (isEditMode) return 'disabled';

    if (index < currentStepIndex) return 'completed';

    return 'disabled';
  };

  return (
    <Box className={classes.header}>
      <Typography className={classes.title}>{title}</Typography>
      <Box display='flex'>
        {STEPS.map((step) => (
          <HeaderStep
            key={step.index}
            isEditMode={isEditMode}
            type={getType(step.index)}
            index={step.index}
            title={step.title}
            setStepIndex={setStepIndex}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Header;
