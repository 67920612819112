import { isEmpty } from 'lodash';
import { DEFAULT_MANUAL_PAGE } from './constants';
import { FileCategory, FormikValues, StepWithId } from './types';

export const generateInitialValues = (stepIds: string[]): FormikValues => {
  const steps: StepWithId[] = stepIds.map((id) => ({
    id,
    title: '',
    description: '',
  }));

  const initialValues: FormikValues = {
    name: '',
    steps,
  };

  return initialValues;
};

export const transformFormikValuesToPayload = (
  values: FormikValues,
  filesByStepIndex: ChecklistForm.FilesByStepIndex
): Checklist => {
  const { steps: prevSteps } = values;

  const steps: Step[] = prevSteps.map(
    ({ id, description, ...rest }, stepIndex) => {
      if (!isEmpty(filesByStepIndex)) {
        const filesData = filesByStepIndex?.[stepIndex];

        const { manual_pages_paths = [], manual_page = 1 } = filesData;
        const limitedManualPage =
          Number(manual_page) > manual_pages_paths?.length
            ? DEFAULT_MANUAL_PAGE
            : manual_page;

        return {
          ...rest,
          description: description || null,
          ...filesData,
          manual_page: limitedManualPage,
        };
      }

      return {
        description: description || null,
        ...rest,
      };
    }
  );

  return {
    ...values,
    steps,
  };
};

export const transformChecklistToFormikValues = (
  checklist: GetChecklistResponse,
  stepIds: string[]
): FormikValues => {
  const { name, steps: checklistSteps } = checklist;

  const steps: StepWithId[] = stepIds.map((id, index) => {
    const { title, description } = checklistSteps[index] || {};

    return {
      id,
      title,
      description,
    };
  });

  const values: FormikValues = {
    name,
    steps,
  };

  return values;
};

export const getFileName = (
  filesData: StepFilesData,
  category: FileCategory
): string => filesData?.[category] || '';

export const getOriginalFileName = (fileName: string): string => {
  const [, , , originalFileName] = fileName.split('_');

  return originalFileName;
};
