export const formatDate = (date: string): string => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  return new Date(date).toLocaleString('en-US', dateOptions);
};
