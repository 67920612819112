import { makeStyles } from '@material-ui/core/styles';

const HEADER_HEIGHT = 64;
const DRAWER_WIDTH = 256;

export default makeStyles({
  mainLayout: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
  },
  drawer: {
    width: DRAWER_WIDTH,
    '& .MuiDrawer-paper': {
      top: HEADER_HEIGHT,
      width: DRAWER_WIDTH,
      backgroundColor: '#455A64',
    },
  },
  menu: {
    '&.MuiList-root': {
      padding: 0,
    },
  },
  menuItem: {
    height: 56,
    color: '#FFF',
    '&.Mui-selected.MuiListItem-root': {
      backgroundColor: '#1976D2',
      '&:hover': {
        '&.Mui-selected': {
          backgroundColor: '#1976D2',
        },
      },
    },
  },
  icon: {
    marginRight: '16px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: HEADER_HEIGHT,
    paddingRight: '30px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    color: '#000',
    background: '#FFFFFF',
  },
  headerInner: {
    justifyContent: 'space-between',
    display: 'flex',
    flex: '1 0 auto',
  },
  openDrawerButton: {
    padding: 0,
    marginLeft: 27,
    marginRight: 19,
  },
  content: {
    boxSizing: 'border-box',
    width: '100%',
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    padding: '48px 68px',
    background: '#ECEFF1',
    overflow: 'auto',
    '&.shifted': {
      marginLeft: DRAWER_WIDTH,
    },
    '&.withoutPadding': {
      padding: 0,
    },
  },
  contentInner: {
    maxWidth: '1048px',
    height: 'auto',
    margin: '0 auto',
  },
  account: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    color: '#546E7A',
    '& .MuiTypography-root:hover': {
      textDecoration: 'underline',
    },
  },
});
