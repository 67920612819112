import { Backdrop, CircularProgress } from '@material-ui/core';
import useStyles from './styles';

interface Props {
  isLoading: boolean;
}

const Loader = ({ isLoading }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.loader} open={isLoading}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default Loader;
