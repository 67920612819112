import { useRef, useEffect } from 'react';

interface Props {
  source: string;
  isPlaying: boolean;
  repeat?: boolean;
}

const RingtonePlayer = ({ source, isPlaying, repeat }: Props): JSX.Element => {
  const playerRef = useRef<any>(null);

  useEffect(() => {
    if (isPlaying) {
      (async (): Promise<any> => {
        await playerRef.current?.play();
      })();
    } else {
      playerRef.current?.pause();
    }
  }, [isPlaying]);

  return (
    <audio ref={playerRef} loop={repeat}>
      <source src={source} type='audio/mpeg' />
      <track kind='captions' />
    </audio>
  );
};

RingtonePlayer.defaultProps = {
  repeat: false,
};

export default RingtonePlayer;
