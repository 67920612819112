import { WEBSOCKET_API_URL } from '../constants/global';

class WebsocketManager {
  private openedSockets: {
    [id: string]: WebSocket;
  };

  private baseUrl: string;

  constructor(baseUrl: string) {
    this.openedSockets = {};
    this.baseUrl = baseUrl;
  }

  open = (id: string, path: string): WebSocket => {
    const { openedSockets, baseUrl } = this;

    if (openedSockets?.[id]) return openedSockets[id];

    const url = `${baseUrl}${path}`;
    const socket = new WebSocket(url);
    openedSockets[id] = socket;

    return socket;
  };

  close = (id: string): void => {
    const { openedSockets } = this;
    const socket = openedSockets[id];

    if (socket) {
      socket.close();

      delete openedSockets[id];
    }
  };
}

export default new WebsocketManager(WEBSOCKET_API_URL);
