import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  createTicketModal: {
    '& .MuiDialog-paper': {
      maxWidth: '100%',
    },
  },
  closeButton: {
    marginRight: -12,
  },
  selectInput: {
    width: 240,
    marginBottom: 56,
  },
  assignInputWrapper: {
    margin: '0 40px',
  },
  shortDescription: {
    width: 360,
    marginBottom: 56,
  },
  longDescription: {
    width: '100%',
    marginBottom: 56,
  },
  footer: {
    justifyContent: 'flex-start',
    padding: 24,
  },
}));
