import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  videoModalRoot: {
    fontSize: '24px',
    lineHeight: '48px',
    fontWeight: 600,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 'calc(100% - 290px)',
    top: 80,
    bottom: 0,
    right: 0,
    left: 264,
    height: 'auto',
    zIndex: 2000,
  },
  video: {
    borderRadius: 4,
  },
  remoteWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  remote: {
    maxHeight: 480,
    width: '100%',
    height: 'auto',
    background: '#333',
    [theme.breakpoints.up('md')]: {
      maxWidth: 480,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 640,
    },
  },
  remoteVideoControls: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    bottom: 0,
    width: '100%',
    maxWidth: 'calc(100% - 32px)',
    right: 0,
    height: 40,
    padding: '8px 16px',
    background: '#fff',
    borderRadius: '0 0 4px 4px',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.up('md')]: {
      maxWidth: 448,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 608,
    },
  },
  displayedName: {
    lineHeight: '24px',
  },
  btn: {
    height: 40,
    '&:hover': {
      opacity: 0.7,
    },
  },
  endCallBtn: {
    '&:hover': {
      background: theme.palette.error.main,
    },
    background: theme.palette.error.main,
    color: '#fff',
  },
  displayDigitalTwin: {
    marginLeft: 16,
  },
}));
