import { useState, ChangeEvent, MouseEvent, useEffect } from 'react';
import { isNull, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button,
  InputLabel,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  FormControl,
  Typography,
  Box,
  Paper,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import useStyles from './styles';
// import { validateEmail, validatePassword } from './validate';
import { loginUser } from './actions';
import { selectError } from './selectors';
import { Path } from '../../constants/router';

interface LoginProps {
  isAuthorized: boolean;
}

export default ({ isAuthorized }: LoginProps): JSX.Element => {
  const classes = useStyles();
  const error = useSelector(selectError);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect((): void => {
    setIsFormValid(isNull(error));
  }, [error]);

  useEffect((): void => {
    setIsSubmitButtonDisabled(isEmpty(email) || isEmpty(password));
  }, [password, email]);

  useEffect((): void => {
    if (isAuthorized) {
      history.push(Path.Equipment);
    }
  }, [history, isAuthorized]);

  const handleChange = ({
    target: { type, value },
  }: ChangeEvent<HTMLInputElement>): void => {
    if (type === 'email') {
      setEmail(value);
    } else {
      setPassword(value);
    }
  };

  const handlePasswordVisibility = (): void => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const handleMouseDown = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
  };

  const handlerSubmit = (): void => {
    // const isEmailValid = validateEmail(email);
    // const isPasswordValid = validatePassword(password);
    // if (isEmailValid && isPasswordValid) {
    dispatch(loginUser({ email, password }));
    // } else {
    // setIsFormValid(isNull(error) && isEmailValid && isPasswordValid);
    // }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Typography variant='h1' className={classes.h1}>
          XR App Web portal
        </Typography>
        <Paper className={classes.form}>
          <Typography variant='h2' className={classes.title}>
            Please login to the system
          </Typography>

          <FormControl variant='outlined' required fullWidth>
            <InputLabel htmlFor='username-field' error={!isFormValid}>
              Username
            </InputLabel>
            <OutlinedInput
              error={!isFormValid}
              id='username-field'
              type='email'
              value={email}
              onChange={handleChange}
              label='Username'
            />
          </FormControl>

          <FormControl
            variant='outlined'
            required
            fullWidth
            className={classes.password}
          >
            <InputLabel htmlFor='password-field' error={!isFormValid}>
              Password
            </InputLabel>
            <OutlinedInput
              error={!isFormValid}
              id='password-field'
              type={isPasswordVisible ? 'text' : 'password'}
              value={password}
              onChange={handleChange}
              label='Password'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle-password-visibility'
                    onClick={handlePasswordVisibility}
                    onMouseDown={handleMouseDown}
                    edge='end'
                  >
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {!isFormValid && (
              <FormHelperText
                id='password-error-descrition'
                className={classes.helperText}
              >
                Invalid Username or Password
              </FormHelperText>
            )}
          </FormControl>
          <Box className={classes.formSubmitWrapper}>
            <Button
              variant='contained'
              type='submit'
              color='primary'
              disabled={isSubmitButtonDisabled}
              onClick={handlerSubmit}
            >
              Sign in
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};
