import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './styles';

interface Props {
  isEditMode: boolean;
  isNextButtonDisabled: boolean;
  onNextButtonClick?(): void;
  onSaveButtonClick?(): void;
}

export const Footer = ({
  isEditMode,
  isNextButtonDisabled,
  onNextButtonClick,
  onSaveButtonClick,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Box display='flex' alignItems='center'>
      {onNextButtonClick && !isEditMode && (
        <>
          <Button
            variant='contained'
            color='primary'
            disabled={isNextButtonDisabled}
            onClick={onNextButtonClick}
            className={classes.button}
          >
            Next
          </Button>
          <Box mr={3}>or</Box>
        </>
      )}
      <Button
        type='submit'
        variant={isEditMode ? 'contained' : 'outlined'}
        color={isEditMode ? 'primary' : 'secondary'}
        onClick={onSaveButtonClick}
        className={clsx(classes.button, 'save')}
      >
        Save equipment
      </Button>
      {!isEditMode && (
        <span className={classes.note}>
          Equipment will be added to the list with the only general info.
          <br />
          Sensors could be added and organised later.
        </span>
      )}
    </Box>
  );
};

Footer.defaultProps = {
  onNextButtonClick: undefined,
  onSaveButtonClick: undefined,
} as Props;
