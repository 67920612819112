import { createSelector } from '@reduxjs/toolkit';

const selectState = (state: RootState): ChecklistForm.State =>
  state.checklistForm;

export const selectRequestStatus = createSelector(
  selectState,
  (state): RequestStatus => state.requestStatus
);

export const selectChecklistForEditing = createSelector(
  selectState,
  (state) => state.checklistForEditing
);

export const selectUploadingFilesByStepIndex = createSelector(
  selectState,
  (state) => state.uploadingFilesByStepIndex
);

export const selectFilesByStepIndex = createSelector(
  selectState,
  (state) => state.filesByStepIndex
);
