import { SagaIterator, eventChannel, EventChannel } from 'redux-saga';
import {
  all,
  call,
  put,
  select,
  delay,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash';

import { getIdToken } from '../../Auth/auth';

import { setIsLoading } from '../../Loader/actions';

import {
  GET_EQUIPMENT_LIST,
  saveEquipmentList,
  setEquipmentListError,
  DOWNLOAD_ARUCO_CODE,
  DELETE_EQUIPMENT,
  OPEN_DEVICE_SOCKET,
  CLOSE_DEVICE_SOCKET,
  setEquipmentSocketData,
} from './actions';
import { websocketManager, gcpApi } from '../../../services';

import { MAGIC_BOX_EQUIPMENT_ID, MAGIC_BOX_2_EQUIPMENT_ID } from './constants';
import { Path } from '../../../constants/router';
import { fetchDevices } from '../Wizard/sagas';
import { selectAvailableDevices } from '../Wizard/selectors';

export function* getEquipmentList(): SagaIterator {
  yield put(setIsLoading(true));
  try {
    const config = {
      params: {
        limit: 0, // 0 To ge all equipment items
      },
    };

    const {
      data: { equipments: equipmentList },
    }: Response<EquipmentList.EquipmentResponse> = yield call(
      gcpApi.get,
      '/equipments',
      config
    );

    yield call(fetchDevices);
    const devices = yield select(selectAvailableDevices);

    const equipmentWithAvailableDevices = equipmentList.map((equipment) => {
      if (isEmpty(equipment.iot_device)) {
        return {
          ...equipment,
          iot_device: equipment.iot_device.map(({ device_id: iotId }: Device) =>
            devices.find(({ device_id }: Device) => iotId === device_id)
          ),
        };
      }
      return equipment;
    });

    const magicBox1Index = equipmentList.findIndex(
      (equipment) => equipment.id === MAGIC_BOX_EQUIPMENT_ID
    );
    const magicBox2Index = equipmentList.findIndex(
      (equipment) => equipment.id === MAGIC_BOX_2_EQUIPMENT_ID
    );

    if (magicBox1Index > -1 || magicBox2Index > -1) {
      // Move Magic box to the top of list
      const sortedEquipmentList = [
        equipmentWithAvailableDevices[magicBox1Index],
        equipmentWithAvailableDevices[magicBox2Index],
        ...equipmentWithAvailableDevices.filter(
          ({ id }) =>
            ![MAGIC_BOX_EQUIPMENT_ID, MAGIC_BOX_2_EQUIPMENT_ID].includes(id)
        ),
      ];
      yield put(saveEquipmentList(sortedEquipmentList));
    } else {
      yield put(saveEquipmentList(equipmentWithAvailableDevices));
    }
  } catch (e) {
    yield put(setEquipmentListError(e));
  } finally {
    yield put(setIsLoading(false));
  }
}
export function* downloadArucoCode({
  payload: { aruco_id, serial_number, name },
}: Action<EquipmentList.EquipmentItem>): SagaIterator {
  yield put(setIsLoading(true));
  try {
    const { data: arucoUrl }: Response<string> = yield call(
      gcpApi.get,
      `/equipments/aruco_url/${aruco_id}`
    );

    saveAs(arucoUrl, `${serial_number} ${name} ArUco code`);

    /*
    TODO:
      + fetch(arucoUrl).then({ body => body.getReader().read()})
      + new ReadableStream({})
      think how to do it together
    FYI: https://developer.mozilla.org/en-US/docs/Web/API/Streams_API/Using_readable_streams
    */
  } catch (e) {
    console.error('downloadArucoCode', e);
  } finally {
    yield put(setIsLoading(false));
  }
}
export function* deleteEquipment({
  payload: { id, history },
}: Action<EquipmentList.Delete>): SagaIterator {
  yield put(setIsLoading(true));
  try {
    yield call(gcpApi.delete, `/equipments/${id}`);
    yield delay(500);
    history.push(Path.Root);
  } catch (e) {
    console.error('deleteEquipment', e);
  } finally {
    yield put(setIsLoading(false));
  }
}

function createSocketChannel(
  socket: WebSocket
): EventChannel<MessageEvent<string>> {
  return eventChannel((emit) => {
    // eslint-disable-next-line no-param-reassign
    socket.onmessage = (event: MessageEvent<string>): void => {
      emit(event);
    };

    return socket.close;
  });
}

function* openDeviceSocket({
  payload: deviceId,
}: Action<string>): SagaIterator {
  const idToken = yield call(getIdToken);
  const path = `/${deviceId}?token=${idToken}`;
  const socket = yield call(websocketManager.open, deviceId, path);
  const socketChannel = createSocketChannel(socket);

  while (true) {
    const event = yield take(socketChannel);

    const data = JSON.parse(event.data);

    if (data) {
      yield put(setEquipmentSocketData({ ...data, device_id: deviceId }));
    }
  }
}

function* closeDeviceSocket({
  payload: deviceId,
}: Action<string>): SagaIterator {
  yield call(websocketManager.close, deviceId);
}

export default function* equipmentListSaga(): SagaIterator {
  yield all([
    takeLatest(GET_EQUIPMENT_LIST, getEquipmentList),
    takeLatest(DOWNLOAD_ARUCO_CODE, downloadArucoCode),
    takeLatest(DELETE_EQUIPMENT, deleteEquipment),
    takeEvery(OPEN_DEVICE_SOCKET, openDeviceSocket),
    takeLatest(CLOSE_DEVICE_SOCKET, closeDeviceSocket),
  ]);
}
