import { makeStyles, Theme } from '@material-ui/core';
import { PADDING } from '../../constants';
import chevronIcon from './chevron.svg';

export default makeStyles((theme: Theme) => ({
  header: {
    padding: PADDING,
    borderBottom: '1px solid #CFD8DC',
  },
  title: {
    marginBottom: '40px',
    fontSize: '34px',
    lineHeight: '40px',
  },
  headerStep: {
    display: 'flex',
    marginRight: '16px',
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.secondary.dark,
    '&.edit': {
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.main,
      },
    },
  },
  headerStepTitle: {
    paddingBottom: '12px',
    whiteSpace: 'nowrap',
    '&.active': {
      borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
    '&.disabled': {
      color: theme.palette.secondary.light,
    },
  },
  chevronIcon: {
    width: '24px',
    height: '24px',
    marginRight: '16px',
    background: theme.palette.secondary.dark,
    mask: `url(${chevronIcon}) no-repeat center`,
    '&.disabled': {
      background: theme.palette.secondary.light,
    },
  },
}));
