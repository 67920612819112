import { createReducer } from '@reduxjs/toolkit';
import {
  TOGGLE_TICKET_MODAL,
  SAVE_CHECKLISTS_FOR_TICKET,
  SAVE_CALL_FROM_LIST,
  SAVE_ASSIGN_TO_LIST,
  SET_IS_TICKET_LOADING,
} from './actions';

const initialState: TicketState = {
  ticketData: null,
  checklists: [],
  assignTo: [],
  callFrom: [],
  isLoading: false,
};

const handlers = {
  [TOGGLE_TICKET_MODAL]: (
    state: TicketState,
    { payload }: Action<TicketData>
  ): TicketState => ({
    ...state,
    ticketData: payload,
  }),
  [SAVE_CHECKLISTS_FOR_TICKET]: (
    state: TicketState,
    { payload }: Action<TicketChecklist[]>
  ): TicketState => ({
    ...state,
    checklists: payload,
  }),
  [SAVE_CALL_FROM_LIST]: (
    state: TicketState,
    { payload }: Action<TicketUser[]>
  ): TicketState => ({
    ...state,
    callFrom: payload,
  }),
  [SAVE_ASSIGN_TO_LIST]: (
    state: TicketState,
    { payload }: Action<TicketUser[]>
  ): TicketState => ({
    ...state,
    assignTo: payload,
  }),
  [SET_IS_TICKET_LOADING]: (
    state: TicketState,
    { payload }: Action<boolean>
  ): TicketState => ({
    ...state,
    isLoading: payload,
  }),
};

export default createReducer<TicketState>(initialState, handlers);
