import { FileCategory, FileFormat } from './types';

export const MAP_FILE_FORMAT_BY_CATEGORY: {
  [key in FileCategory]: FileFormat;
} = {
  manual: '.pdf',
  video: '.mp4',
  animation: '.playable',
};

export const DEFAULT_MANUAL_PAGE = 1;
