import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, TextField, Typography } from '@material-ui/core';
import {
  Autocomplete as MuiAutocomplete,
  FilterOptionsState,
} from '@material-ui/lab';

import { fetchDevices } from '../../actions';
import { selectAvailableDevices } from '../../selectors';

import useStyles from './styles';

interface Props {
  selectedDevices: { [key: string]: Device };
  isDisplayed: boolean;
  onChange(e: any, value: Device | null): void;
  onButtonClick(): void;
}

const Autocomplete = ({
  selectedDevices,
  isDisplayed,
  onChange,
  onButtonClick,
}: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const availableDevices = useSelector(selectAvailableDevices);

  const renderInput = (params: any): JSX.Element => (
    <TextField {...params} label='Sensor ID' variant='outlined' />
  );

  const renderOption = ({ device_id, sensors }: Device): JSX.Element => {
    const numberOfSensors = sensors.length;
    const isSensorSingle = numberOfSensors === 1;
    const numberOfSensorsText = `${numberOfSensors} ${
      isSensorSingle ? 'sensor' : 'sensors'
    }`;

    return (
      <Box
        width='100%'
        height='100%'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <span>{device_id}</span> <span>{numberOfSensorsText}</span>
      </Box>
    );
  };

  const handleInputChange = (e: unknown, value: string): void => {
    if (value.length && !availableDevices.length) {
      dispatch(fetchDevices());
    }
  };

  const noOptionsText = (
    <Typography color='error'>No sensor groups/sensors found</Typography>
  );

  const getOptionLabel = ({ device_id }: Device): string => device_id;

  const filterOptions = (
    options: Device[],
    { inputValue }: FilterOptionsState<Device>
  ): Device[] => {
    const selectedDeviceIds = Object.keys(selectedDevices);

    return options.filter(({ device_id, sensors }) => {
      const isDeviceSelected = selectedDeviceIds.includes(device_id);
      const hasSensors = sensors.length;
      const isMatchedWithSearch = device_id.includes(inputValue);

      return !isDeviceSelected && hasSensors && isMatchedWithSearch;
    });
  };

  return (
    <Box mb={2}>
      {isDisplayed ? (
        <MuiAutocomplete
          options={availableDevices}
          noOptionsText={noOptionsText}
          getOptionLabel={getOptionLabel}
          filterOptions={filterOptions}
          renderInput={renderInput}
          renderOption={renderOption}
          onInputChange={handleInputChange}
          onChange={onChange}
          className={classes.autocomplete}
        />
      ) : (
        <Button
          variant='outlined'
          color='secondary'
          className={classes.addSensorsButton}
          onClick={onButtonClick}
        >
          ADD SENSORS
        </Button>
      )}
    </Box>
  );
};

export default Autocomplete;
