import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  subheading: {
    marginBottom: 32,
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.secondary.dark,
  },
  text: {
    '&.equipmentName': {
      marginTop: 8,
      marginBottom: 32,
      fontSize: 12,
      lineHeight: 1.25,
    },
  },
  textField: {
    width: 328,
    '&.serialNumber': {
      marginBottom: 56,
    },
    '&.location': {
      marginBottom: 64,
    },
  },
}));
