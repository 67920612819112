import clsx from 'clsx';

import { TableCell, IconButton } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import useStyles from '../styles';

interface Props {
  isFirstSensor: boolean;
  isLastSensor: boolean;
  openMenu(e: any): void;
}

export const LastCell = ({
  isFirstSensor,
  isLastSensor,
  openMenu,
}: Props): JSX.Element => {
  const classes = useStyles();

  return isFirstSensor ? (
    <TableCell className={classes.withoutBorder}>
      <IconButton onClick={openMenu} className={classes.openMenuButton}>
        <MoreHorizIcon color='secondary' />
      </IconButton>
    </TableCell>
  ) : (
    <TableCell className={clsx({ [classes.withoutBorder]: !isLastSensor })} />
  );
};
