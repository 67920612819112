import { createReducer } from '@reduxjs/toolkit';
import { SAVE_USERS } from './actions';

const initialState: PeopleState = {
  users: [],
};

const handlers = {
  [SAVE_USERS]: (
    state: PeopleState,
    { payload }: Action<User[]>
  ): PeopleState => ({
    ...state,
    users: payload,
  }),
};

export default createReducer<PeopleState>(initialState, handlers);
