import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  textField: {
    width: 336,
    marginTop: theme.spacing(4),
    '&.stepFields': {
      marginTop: 28,
      '&.description': {
        marginTop: theme.spacing(7),
      },
    },
  },
  buttonsContainer: {
    display: 'table',
    marginLeft: 104,
    paddingTop: 52,
  },
  button: {
    display: 'block',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '&.stepFields': {
      padding: 12,
    },
    '&.saveChecklist': {
      color: '#FFF',
      padding: theme.spacing(2),
    },
  },
  divider: {
    height: '1px',
    backgroundColor: '#CFD8DC',
  },
  fileInput: {
    display: 'none',
  },
  fileNameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(7),
  },
  fileName: {
    marginRight: theme.spacing(2),
    textDecoration: 'underline',
  },
  deleteFileButton: {
    padding: 0,
  },
  deleteFileIcon: {
    fill: theme.palette.secondary.main,
  },
  manualPageInput: {
    marginLeft: 40,
    '& .MuiInputBase-input': {
      width: 48,
      padding: 16,
      height: 24,
      '&::-webkit-outer-spin-button,::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
}));
