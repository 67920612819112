import { KeyPageElements } from '../../../constants/global';

export enum StepIndex {
  GeneralInformation,
  Sensors,
}

export interface Step {
  index: StepIndex;
  title: string;
}

export type StepType = 'active' | 'completed' | 'disabled';

export const STEPS: Step[] = [
  {
    index: StepIndex.GeneralInformation,
    title: KeyPageElements.GeneralInformation,
  },
  {
    index: StepIndex.Sensors,
    title: KeyPageElements.Sensors,
  },
];

export const STEP_INDEXES: StepIndex[] = [
  StepIndex.GeneralInformation,
  StepIndex.Sensors,
];

export const PADDING = '48px 72px';
