import { ChangeEvent } from 'react';
import clsx from 'clsx';
import { FormikProps, FormikErrors } from 'formik';

import { Box, Button, TextField, Typography } from '@material-ui/core';

import UploadFileButtonGroup from './UploadFileButtonGroup';
import { FormikValues, StepWithId } from '../types';
import useStyles from './styles';

interface Props {
  index: number;
  isRemovable: boolean;
  formik: FormikProps<FormikValues>;
  removeStep(): void;
}

const StepFields = ({
  index,
  isRemovable,
  formik,
  removeStep,
}: Props): JSX.Element => {
  const classes = useStyles();

  const {
    values: { steps },
    setFieldValue,
    errors,
  } = formik;
  const step = steps[index] || {};
  const stepErrors = (errors?.steps || [])[index] as FormikErrors<StepWithId>;
  const { title, description } = step;

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = e.target;

    const nextStep = { ...step, [name]: value };

    const nextSteps = [...steps];
    nextSteps.splice(index, 1, nextStep);

    setFieldValue('steps', nextSteps);
  };

  return (
    <Box pt={5} pb={6} px={9} display='flex'>
      <Box display='flex' flexDirection='column'>
        <Typography variant='h5'>Step {index + 1}</Typography>
        <TextField
          required
          autoComplete='off'
          variant='outlined'
          label='Title'
          name='title'
          value={title || ''}
          helperText={stepErrors?.title}
          error={Boolean(stepErrors?.title)}
          onChange={handleFieldChange}
          className={clsx(classes.textField, 'stepFields')}
        />
        <TextField
          multiline
          autoComplete='off'
          variant='outlined'
          label='Description'
          name='description'
          minRows={3}
          value={description || ''}
          helperText={stepErrors?.description}
          error={Boolean(stepErrors?.description)}
          onChange={handleFieldChange}
          className={clsx(classes.textField, 'description', 'stepFields')}
        />
      </Box>
      <UploadFileButtonGroup stepIndex={index} />
      <Box ml='auto' pt='52px'>
        <Button
          variant='outlined'
          disabled={!isRemovable}
          onClick={removeStep}
          className={classes.button}
        >
          Remove step
        </Button>
      </Box>
    </Box>
  );
};

export default StepFields;
