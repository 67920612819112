import { createAction } from '@reduxjs/toolkit';

export const GET_EQUIPMENT_LIST = 'INIT_EQUIPMENT_LIST';
export const SAVE_EQUIPMENT_LIST = 'SAVE_EQUIPMENT_LIST';
export const SET_EQUIPMENT_LIST_ERROR = 'SET_EQUIPMENT_LIST_ERROR';
export const DOWNLOAD_ARUCO_CODE = 'DOWNLOAD_ARUCO_CODE';
export const DELETE_EQUIPMENT = 'DELETE_EQUIPMENT';

export const getEquipmentList = createAction(GET_EQUIPMENT_LIST);
export const saveEquipmentList =
  createAction<EquipmentList.EquipmentItem[]>(SAVE_EQUIPMENT_LIST);
export const setEquipmentListError = createAction<any>(
  SET_EQUIPMENT_LIST_ERROR
);
export const downloadArucoCode =
  createAction<EquipmentList.EquipmentItem>(DOWNLOAD_ARUCO_CODE);
export const deleteEquipment =
  createAction<EquipmentList.Delete>(DELETE_EQUIPMENT);

export const OPEN_DEVICE_SOCKET = 'OPEN_DEVICE_SOCKET';
export const openDeviceSocket = createAction<string>(OPEN_DEVICE_SOCKET);

export const CLOSE_DEVICE_SOCKET = 'CLOSE_DEVICE_SOCKET';
export const closeDeviceSocket = createAction<string>(CLOSE_DEVICE_SOCKET);

export const SET_DEVICE_SOCKET_DATA = 'SET_DEVICE_SOCKET_DATA';
export const setEquipmentSocketData = createAction<DeviceSocketData>(
  SET_DEVICE_SOCKET_DATA
);
