import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './styles';

interface Props {
  shouldOpen: boolean;
  onClose(): void;
  onConfirm(): void;
  title: string;
}

const DeleteModal = ({
  title,
  shouldOpen,
  onClose,
  onConfirm,
}: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Dialog
      open={shouldOpen}
      onClose={onClose}
      className={classes.modal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          This cannot be undone
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <Button onClick={onClose} variant='outlined'>
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          className={classes.confirm}
          variant='contained'
        >
          Yes, delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
