import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Button, Snackbar, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import { isNull, isEmpty } from 'lodash';

import MainLayout from '../MainLayout';
import ChecklistForm from '../ChecklistForm';

import ChecklistsTable from './ChecklistsTable';

import useStyles from './styles';
import DeleteModal from '../EquipmentPage/List/components/DeleteModal';
import { deleteChecklist } from './actions';

const ChecklistsPage = (): JSX.Element => {
  const [isFormOpened, setIsFormOpened] = useState(false);
  const [editChecklistId, setEditChecklistId] = useState('');
  const [showWarningModal, setShowWarningModal] = useState<string | null>(null);
  const dispatch = useDispatch();

  const openForm = (): void => {
    setIsFormOpened(true);
  };

  const closeForm = (): void => {
    setIsFormOpened(false);
    setEditChecklistId('');
  };

  const openChecklistToEdit = (checklistId: string): void => {
    openForm();
    setEditChecklistId(checklistId);
  };

  const onDeleteChecklist = (checklistId: string): void => {
    setShowWarningModal(checklistId);
  };

  const onCancelDelete = (): void => {
    setShowWarningModal(null);
  };

  const onConfirmDelete = (): void => {
    if (showWarningModal) {
      dispatch(deleteChecklist(showWarningModal));
      onCancelDelete();
    }
  };

  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  const openSnackbar = (isEditMode: boolean): void => {
    const message = isEditMode
      ? 'Checklist is updated'
      : 'Checklist is created';

    setSnackbarMessage(message);
  };

  const handleSnackbarClose = (): void => {
    setSnackbarMessage('');
  };

  const classes = useStyles();

  return (
    <MainLayout withoutPadding>
      <Box p={8}>
        {isFormOpened ? (
          <ChecklistForm
            editChecklistId={editChecklistId}
            closeForm={closeForm}
            openSnackbar={openSnackbar}
          />
        ) : (
          <Box>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant='h2'>Checklists</Typography>
              <Button
                variant='outlined'
                className={classes.addChecklistButton}
                onClick={openForm}
              >
                <AddIcon />
                Add checklist
              </Button>
            </Box>
            <ChecklistsTable
              openChecklistToEdit={openChecklistToEdit}
              deleteChecklist={onDeleteChecklist}
            />
            <DeleteModal
              title='Delete the checklist?'
              shouldOpen={
                !(isNull(showWarningModal) || isEmpty(showWarningModal))
              }
              onConfirm={onConfirmDelete}
              onClose={onCancelDelete}
            />
          </Box>
        )}
        <Snackbar
          autoHideDuration={6000}
          open={Boolean(snackbarMessage)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity='success'>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </MainLayout>
  );
};

export default ChecklistsPage;
