import { createAction } from '@reduxjs/toolkit';

export const FETCH_USER_TO_CALL = 'FETCH_USER_TO_CALL';
export const SAVE_USER_TO_CALL = 'SAVE_USER_TO_CALL';

export const SHOW_VIDEO_MODAL = 'SHOW_VIDEO_MODAL';
export const SET_MEDIA_CONFIG = 'SET_MEDIA_CONFIG';
export const SET_CALL_MODE = 'SET_CALL_MODE';
export const SET_REMOTE_STREAM = 'SET_REMOTE_STREAM';

export const saveUserToCall = createAction<User | null>(SAVE_USER_TO_CALL);
export const fetchUserToCall = createAction<string>(FETCH_USER_TO_CALL);

export const showVideoModal = createAction<boolean>(SHOW_VIDEO_MODAL);
export const setMediaConfig = createAction<MediaConfig>(SET_MEDIA_CONFIG);
export const setCallingMode = createAction<CallingMode>(SET_CALL_MODE);
export const setRemoteStream = createAction<MediaStream | null>(
  SET_REMOTE_STREAM
);

/* Socket actions */
export const INIT_WEBRTC_SOCKET = 'INIT_WEBRTC_SOCKET';
export const SOCKET_START_CALL = 'SOCKET_START_CALL';
export const SOCKET_END_CALL = ' SOCKET_END_CALL';
export const SOCKET_USER_IS_BUSY = 'SOCKET_USER_IS_BUSY';

export const initWebRTCSocket = createAction(INIT_WEBRTC_SOCKET);

export const socketStartCall = createAction<User>(SOCKET_START_CALL);
export const socketUserIsBusy = createAction<User>(SOCKET_USER_IS_BUSY);
export const socketEndCall = createAction(SOCKET_END_CALL);
