import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, RouteChildrenProps } from 'react-router';
import { Button, Box, Typography, Paper } from '@material-ui/core';
import {
  ChevronLeft,
  EditOutlined,
  DeleteOutline,
  AddCommentOutlined,
} from '@material-ui/icons';
import { isUndefined } from 'lodash';
import QRcodeIcon from '../EquipmentPage/List/components/QRcodeIcon';
import { Path } from '../../constants/router';
import MainLayout from '../MainLayout';
import { selectEquipment } from './selectors';
import useStyles from './styles';

import {
  deleteEquipment,
  downloadArucoCode,
  getEquipmentList,
} from '../EquipmentPage/List/actions';
import DeleteModal from '../EquipmentPage/List/components/DeleteModal';
import {
  MAGIC_BOX_EQUIPMENT_ID,
  MAGIC_BOX_2_EQUIPMENT_ID,
} from '../EquipmentPage/List/constants';
import { useManageIotDevicesSocket } from '../EquipmentPage/List/hooks';

import ButtonsContainer, { Options } from './ButtonsContainer';
import Header, { HeaderProps } from './Header';
import SensorGroupContainer from './SensorGroupContainer';
import { toggleTicketModal } from '../Ticket/actions';
import TicketModal from '../Ticket';

interface Params {
  id: string;
}

const Details = ({
  history,
  match,
}: RouteChildrenProps<Params>): JSX.Element | null => {
  const id: string = match?.params.id || '';
  const classes = useStyles();
  const dispatch = useDispatch();
  const equipment = useSelector(selectEquipment(id));
  const [shouldModalOpen, setShouldModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isUndefined(equipment)) {
      dispatch(getEquipmentList());
    }
  }, [equipment]);

  useManageIotDevicesSocket(equipment?.iot_device || []);

  if (!equipment) return null;

  const {
    name,
    serial_number,
    location: { name: locationName = '' },
    created,
    updated,
  } = equipment;

  const goToEquipmentEdit = (): void => {
    history.push(generatePath(Path.EquipmentEdit, { id }));
  };

  const options: Options[] = [
    {
      title: 'Create ticket',
      onClick: (): void => {
        dispatch(toggleTicketModal({ name, serialNumber: serial_number }));
      },
      iconStyles: classes.icon,
      icon: AddCommentOutlined,
    },
    {
      title: 'Download code',
      onClick: (): void => {
        dispatch(downloadArucoCode(equipment));
      },
      iconStyles: classes.icon,
      icon: QRcodeIcon,
    },
    {
      title: 'Edit',
      onClick: goToEquipmentEdit,
      iconStyles: classes.icon,
      icon: EditOutlined,
    },
    {
      title: 'Delete',
      onClick: (): void => {
        setShouldModalOpen(true);
      },
      iconStyles: classes.icon,
      icon: DeleteOutline,
    },
  ];

  const onClose = (): void => {
    setShouldModalOpen(false);
  };

  const onConfirm = (): void => {
    dispatch(deleteEquipment({ id, history }));
    onClose();
  };

  // Do not allow to remove Magic Box Equipment
  if ([MAGIC_BOX_EQUIPMENT_ID, MAGIC_BOX_2_EQUIPMENT_ID].includes(id))
    options.pop();

  const headerProps: HeaderProps = {
    serialNumber: serial_number,
    location: locationName,
    created,
    updated,
  };

  const sensors = equipment.iot_device.flatMap(({ sensors: data }) => data);

  return (
    <MainLayout>
      <Box>
        <Button
          onClick={(): void => history.push(Path.Equipment)}
          variant='outlined'
        >
          <ChevronLeft /> Back
        </Button>
        <Box className={classes.header}>
          <Typography variant='h1' className={classes.title}>
            {name} #{serial_number}
          </Typography>
          <ButtonsContainer
            className={classes.buttonsContainer}
            options={options}
          />
        </Box>
        <Paper>
          <Header {...headerProps} />
          <SensorGroupContainer
            className={classes.sensorGroupContainer}
            sensors={sensors}
          />
        </Paper>
        <DeleteModal
          title='Delete the equipment?'
          shouldOpen={shouldModalOpen}
          onConfirm={onConfirm}
          onClose={onClose}
        />
        <TicketModal />
      </Box>
    </MainLayout>
  );
};

export default Details;
