import { Box } from '@material-ui/core';

import { FileCategory, ButtonGroupFiles } from '../types';

import UploadFileButton from './UploadFileButton';
import useStyles from './styles';

const initialFiles: ButtonGroupFiles = {
  manual: null,
  video: null,
  animation: null,
};

interface Props {
  stepIndex: number;
}

const UploadFileButtonGroup = ({ stepIndex }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.buttonsContainer}>
      {(Object.keys(initialFiles) as FileCategory[]).map((category) => (
        <UploadFileButton
          key={category}
          category={category}
          stepIndex={stepIndex}
        />
      ))}
    </Box>
  );
};

export default UploadFileButtonGroup;
