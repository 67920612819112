import { useState, useRef, useEffect } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { MicOff, Mic, VideocamOff, Videocam } from '@material-ui/icons';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { selectUserToCall } from '../../selectors';
import useStyles from './styles';
import { insertToDOM, RPCHandler, Signaling } from '../../utils';
import { SignalingMessageType } from '../../constants';
import { socketEndCall } from '../../actions';

type DeviceType = 'audio' | 'video';

interface Props {
  remoteSrc: MediaStream;
  config: MediaConfig;
}

const VideoModal = ({ remoteSrc, config }: Props): JSX.Element | null => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(selectUserToCall);

  const remoteVideo = useRef<any>();
  const onFinish = (): void => {
    dispatch(socketEndCall());
    Signaling.emit(SignalingMessageType.END_CALL, { to: user?.uid });
  };
  const [video, setVideo] = useState(config?.video);
  const [audio, setAudio] = useState(config?.audio);

  useEffect((): void => {
    if (remoteVideo.current && remoteSrc) {
      const source: MediaStream = remoteSrc;
      remoteVideo.current.srcObject = source;
    }
  }, [remoteSrc]);

  useEffect(() => {
    window.addEventListener('beforeunload', onFinish);

    return (): void => {
      window.removeEventListener('beforeunload', onFinish);
    };
  }, []);

  const toggleMediaDevice = (deviceType: DeviceType) => (): void => {
    const instance = RPCHandler.getInstance();

    if (instance && deviceType === 'video') {
      setVideo(!video);
      instance.toggleVideo(!video);
    }
    if (instance && deviceType === 'audio') {
      setAudio(!audio);
      instance.toggleAudio(!audio);
    }
  };

  if (!user) return null;

  const { displayName, email } = user;
  const userName = isEmpty(displayName) ? email : displayName;

  return createPortal(
    <Box className={classes.videoModalRoot}>
      <Box className={classes.remoteWrapper}>
        <video
          className={clsx(classes.video, classes.remote)}
          ref={remoteVideo}
          autoPlay
        >
          <track kind='captions' />
        </video>
        <Box className={classes.remoteVideoControls}>
          <Typography variant='h6' className={classes.displayedName}>
            {userName}
          </Typography>
          <Box>
            <Button type='button' onClick={toggleMediaDevice('video')}>
              {video ? <Videocam /> : <VideocamOff />}
            </Button>
            <Button type='button' onClick={toggleMediaDevice('audio')}>
              {audio ? <Mic /> : <MicOff />}
            </Button>
          </Box>
          <Box>
            <Button
              className={clsx(classes.endCallBtn, classes.btn)}
              onClick={onFinish}
            >
              End call
            </Button>
            {/*
            * TODO: uncomment when it will be needed to implement Digital twin
            *
           <Button
              variant='outlined'
              className={clsx(classes.displayDigitalTwin, classes.btn)}
            >
              Display digital twin
            </Button> */}
          </Box>
        </Box>
      </Box>
    </Box>,
    insertToDOM('video-window-root')
  );
};

export default VideoModal;
