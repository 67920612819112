import { createSelector } from '@reduxjs/toolkit';

export const selectTicket = (state: RootState): TicketState => state.ticket;

export const selectTicketData = createSelector(
  selectTicket,
  (ticket) => ticket.ticketData
);

export const selectChecklistsForTicket = createSelector(
  selectTicket,
  (ticket) => ticket.checklists
);

export const selectAssignToList = createSelector(
  selectTicket,
  (ticket) => ticket.assignTo
);

export const selectCallFromList = createSelector(
  selectTicket,
  (ticket) => ticket.callFrom
);

export const selectTicketLoading = createSelector(
  selectTicket,
  (ticket) => ticket.isLoading
);
