import { createReducer } from '@reduxjs/toolkit';

import { FETCH_CHECKLISTS_SUCCESS } from './actions';

type State = ChecklistsPageState;

const initialState: State = {
  checklists: [],
};

const handlers = {
  [FETCH_CHECKLISTS_SUCCESS]: (
    state: State,
    { payload: checklists }: Action<GetChecklistsResponse>
  ): State => ({
    ...state,
    checklists,
  }),
};

export default createReducer<State>(initialState, handlers);
