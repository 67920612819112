import { useRef, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';

import {
  uploadChecklistFile,
  deleteChecklistFile,
  setManualPage,
} from '../actions';
import { MAP_FILE_FORMAT_BY_CATEGORY } from '../constants';
import { useSelectFilesData, useSelectIsFileUploading } from '../hooks';
import { FileCategory } from '../types';
import { getFileName, getOriginalFileName } from '../utils';

import useStyles from './styles';

interface Props {
  stepIndex: number;
  category: FileCategory;
}

const UploadFileButton = ({ stepIndex, category }: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const uploadFileInput = useRef<HTMLInputElement | null>(null);
  const openFileDialog = (): void => {
    (uploadFileInput?.current as HTMLInputElement).click();
  };

  const handleChangeManualPageInput = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;

    const intValue = parseInt(value, 10);
    const isInteger = Number.isInteger(intValue);

    if (!value) {
      dispatch(setManualPage({ stepIndex, manualPage: null }));
    }

    if (isInteger) {
      dispatch(setManualPage({ stepIndex, manualPage: intValue }));
    }
  };

  const filesData = useSelectFilesData(stepIndex);

  const serverFileName = getFileName(filesData, category);
  const originalFileName = getOriginalFileName(serverFileName);
  const manualPage = filesData.manual_page || '';

  const handleDeleteButtonClick = (): void => {
    dispatch(deleteChecklistFile({ serverFileName, category, stepIndex }));
  };

  const renderFileNameContainer = (): JSX.Element => (
    <Box className={classes.fileNameContainer}>
      <Typography color='secondary' className={classes.fileName}>
        {originalFileName}
      </Typography>
      <IconButton
        disableRipple
        onClick={handleDeleteButtonClick}
        className={classes.deleteFileButton}
      >
        <DeleteOutline className={classes.deleteFileIcon} />
      </IconButton>
      {category === 'manual' && (
        <TextField
          value={manualPage}
          type='text'
          variant='outlined'
          label='Page #'
          onChange={handleChangeManualPageInput}
          className={classes.manualPageInput}
        />
      )}
    </Box>
  );

  const handleFileInputChange = (e: any): void => {
    const [rawFile] = e.target.files;

    dispatch(
      uploadChecklistFile({
        stepIndex,
        category,
        rawFile,
      })
    );
  };

  const isUploading = useSelectIsFileUploading(stepIndex, category);

  if (isUploading) {
    return (
      <Box display='flex' mb={3}>
        <CircularProgress size={34} />
      </Box>
    );
  }

  return originalFileName ? (
    renderFileNameContainer()
  ) : (
    <Box display='flex'>
      <input
        type='file'
        accept={MAP_FILE_FORMAT_BY_CATEGORY[category]}
        onChange={handleFileInputChange}
        ref={uploadFileInput}
        className={classes.fileInput}
      />
      <Button
        variant='outlined'
        onClick={openFileDialog}
        className={classes.button}
      >
        Upload {category}
      </Button>
    </Box>
  );
};

export default UploadFileButton;
