import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  header: {
    margin: '24px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '34px',
    lineHeight: '40px',
    fontWeight: 400,
  },
  buttonsContainer: {
    height: '100%',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    boxSizing: 'border-box',
    '& .MuiButtonBase-root:not(:last-child)': {
      border: 0,
      borderRadius: 0,
      height: '100%',
      borderRight: '1px solid rgba(0, 0, 0, 0.23)',
      boxSizing: 'border-box',
    },
  },
  icon: {
    marginRight: '8px',
  },
  details: {
    padding: '24px 16px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
    display: 'flex',
    justifyContent: 'space-between',
  },
  keyDetail: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  valueDetail: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  sensorGroupContainer: {
    padding: '24px 16px',
  },
}));
