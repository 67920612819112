import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  equipment: {
    padding: '16px',
    marginTop: '16px',
  },
  title: {
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
  },
}));
