import { Typography, Box, Paper } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

interface Props {
  children: React.ReactChild;
  avatar: string;
  userName: string;
  busy?: boolean;
}

const Modal = ({ busy, children, avatar, userName }: Props): JSX.Element => {
  const classes = useStyles();

  const callingText = busy ? 'Busy' : 'Calling...';

  return (
    <Paper className={clsx(classes.callingModeModalRoot, { busy })}>
      <Box display='flex'>
        <img src={avatar} alt={userName} width={48} height={48} />
        <Box display='inline-block' ml={2}>
          <Typography className={classes.userName}>{userName}</Typography>
          <Typography className={classes.calling}>{callingText}</Typography>
        </Box>
      </Box>
      <Box display='flex' justifyContent='space-between' mt={3}>
        {children}
      </Box>
    </Paper>
  );
};

Modal.defaultProps = {
  busy: false,
};

export default Modal;
