import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

const init = (): FirebaseApp => {
  if (process.env.REACT_APP_FIREBASE_CONFIG) {
    return initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
  }
  throw new Error('Firebase config is not set');
};

const app: FirebaseApp = init();
const auth = getAuth(app);

const logout = (): void => {
  signOut(auth)
    .then((resp) => resp)
    .catch((error) => error);
};

const signIn = ({ email, password }: UserCredentials): Promise<any> =>
  signInWithEmailAndPassword(auth, email, password);

const getIdToken = (): Promise<string> | string | undefined =>
  auth.currentUser?.getIdToken();

export { app, auth, signIn, logout, getIdToken };
