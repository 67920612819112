import { useEffect, useState } from 'react';
import { Paper, Box, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import {
  DescriptionOutlined,
  EditOutlined,
  DeleteOutline,
  AddCommentOutlined,
} from '@material-ui/icons';
import useStyles from './styles';
import Menu from './Menu';
import { Option } from './Menu/Menu';
import SensorGroup from './SensorGroup';
import QRcodeIcon from './QRcodeIcon';

import {
  downloadArucoCode,
  deleteEquipment,
  openDeviceSocket,
  closeDeviceSocket,
} from '../actions';

import DeleteModal from './DeleteModal';
import { Path } from '../../../../constants/router';

import { MAGIC_BOX_2_EQUIPMENT_ID, MAGIC_BOX_EQUIPMENT_ID } from '../constants';
import { toggleTicketModal } from '../../../Ticket/actions';

const Equipment = (props: EquipmentList.EquipmentItem): JSX.Element => {
  const { name, serial_number, iot_device, id } = props;
  const [shouldModalOpen, setShouldModalOpen] = useState<boolean>(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const openDetails = (): void => {
    history.push(generatePath(Path.EquipmentDetails, { id }));
  };

  const goToEquipmentEdit = (): void => {
    history.push(generatePath(Path.EquipmentEdit, { id }));
  };

  const options: Option[] = [
    {
      key: 'View details',
      icon: DescriptionOutlined,
      onClick: openDetails,
    },
    {
      key: 'Create ticket',
      icon: AddCommentOutlined,
      onClick: (): void => {
        dispatch(toggleTicketModal({ name, serialNumber: serial_number }));
      },
    },
    {
      key: 'Download code',
      icon: QRcodeIcon,
      onClick: (): void => {
        dispatch(downloadArucoCode(props));
      },
    },
    {
      key: 'Edit',
      icon: EditOutlined,
      onClick: goToEquipmentEdit,
    },
    {
      key: 'Delete',
      icon: DeleteOutline,
      onClick: (): void => setShouldModalOpen(true),
    },
  ];

  // Do not allow to remove Magic Box Equipment
  if ([MAGIC_BOX_EQUIPMENT_ID, MAGIC_BOX_2_EQUIPMENT_ID].includes(id))
    options.pop();

  const onClose = (): void => {
    setShouldModalOpen(false);
  };

  const onConfirm = (): void => {
    dispatch(deleteEquipment({ id, history }));
    onClose();
  };

  const sensors = iot_device.flatMap(({ sensors: data }) => data);

  useEffect(() => {
    iot_device.forEach(({ device_id }) => {
      dispatch(openDeviceSocket(device_id));
    });

    return (): void => {
      iot_device.forEach(({ device_id }) => {
        dispatch(closeDeviceSocket(device_id));
      });
    };
  }, []);

  return (
    <Paper className={classes.equipment}>
      <Box display='flex' justifyContent='space-between'>
        <Typography
          className={classes.title}
          variant='h4'
          onClick={openDetails}
        >
          {name} ({serial_number})
        </Typography>
        <Menu options={options} />
      </Box>
      <SensorGroup sensors={sensors} />
      <DeleteModal
        title='Delete the equipment?'
        shouldOpen={shouldModalOpen}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </Paper>
  );
};

export default Equipment;
