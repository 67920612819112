import { Box, Typography } from '@material-ui/core';
import SensorGroup from '../EquipmentPage/List/components/SensorGroup';

interface Props {
  className: string;
  sensors: EquipmentList.SensorItem[];
}

const SensorGroupContainer = ({ className, sensors }: Props): JSX.Element => (
  <Box className={className}>
    <Typography variant='h6'>
      Added sensors ({sensors.filter(({ enabled }) => enabled).length}):
    </Typography>
    <SensorGroup sensors={sensors} />
  </Box>
);

export default SensorGroupContainer;
