import { createReducer } from '@reduxjs/toolkit';
import { SAVE_USER_DATA, SET_LOGIN_USER_ERROR } from './actions';

const initialState: AuthState = {
  uid: '',
  email: '',
  error: null,
};

export default createReducer<AuthState>(initialState, {
  [SAVE_USER_DATA]: (
    state: AuthState,
    { payload: { uid, email } }
  ): AuthState => ({
    ...state,
    uid,
    email,
  }),
  [SET_LOGIN_USER_ERROR]: (
    state: AuthState,
    { payload: { error } }
  ): AuthState => ({
    ...state,
    error,
  }),
});
