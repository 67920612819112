import React, { useEffect, useState } from 'react';

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { FirstCell, CheckboxCell, LastCell } from './components';
import useStyles from './styles';

interface Props {
  error?: boolean;
  device: Device;
  deleteDevice(): void;
  onSensorSelect(sensorIds: string[]): void;
}

const DeviceTable = ({
  error,
  device,
  deleteDevice,
  onSensorSelect,
}: Props): JSX.Element => {
  const classes = useStyles();
  const enabledSensorIds: string[] = device.sensors
    .filter(({ enabled }) => enabled)
    .map(({ id }) => id);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedSensors, setSelectedSensors] =
    useState<string[]>(enabledSensorIds);

  const totalSelectedSensors = selectedSensors.length;
  const totalSensors = device.sensors.length;
  const isAllSensorsCheckboxSelected = totalSelectedSensors === totalSensors;

  const handleCheckboxChange = (e: React.ChangeEvent<any>): void => {
    const { id, checked } = e.target;

    if (checked) {
      setSelectedSensors((prevState) => [...prevState, id]);
    } else {
      setSelectedSensors((prevState) =>
        prevState.filter((item) => item !== id)
      );
    }
  };

  const handleAllCheckboxChange = (_: unknown, value: boolean): void => {
    const allSensorIds: string[] = device.sensors.map(({ id }) => id);
    setSelectedSensors(value ? allSensorIds : []);
  };

  const openMenu = (e: any): void => {
    setMenuAnchorEl(e.currentTarget);
  };

  const closeMenu = (): void => {
    setMenuAnchorEl(null);
  };

  const renderBody = (): JSX.Element => {
    const { device_id, sensors } = device;

    if (!totalSensors) {
      return <TableBody>No sensor groups/sensors found</TableBody>;
    }

    const lastSensorIndex = totalSensors - 1;

    return (
      <TableBody className={classes.deviceTableBody}>
        {sensors.map(({ id, units, enabled, attribute }, index) => (
          <TableRow key={id}>
            <FirstCell
              isFirstSensor={index === 0}
              isLastSensor={index === lastSensorIndex}
              deviceId={device_id}
            />
            <CheckboxCell
              id={id}
              enabled={enabled}
              handleCheckboxChange={handleCheckboxChange}
            />
            <TableCell>{attribute}</TableCell>
            <TableCell>{units}</TableCell>
            <LastCell
              isFirstSensor={index === 0}
              isLastSensor={index === lastSensorIndex}
              openMenu={openMenu}
            />
          </TableRow>
        ))}
      </TableBody>
    );
  };

  useEffect(() => {
    onSensorSelect(selectedSensors);
  }, [totalSelectedSensors]);

  return (
    <TableContainer component={Paper} className={classes.deviceTable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={200}>ID</TableCell>
            <TableCell width={56}>
              <Checkbox
                color='primary'
                indeterminate={
                  totalSelectedSensors > 0 &&
                  totalSelectedSensors < totalSensors
                }
                checked={isAllSensorsCheckboxSelected}
                onChange={handleAllCheckboxChange}
                className={classes.checkbox}
              />
            </TableCell>
            <TableCell width={200}>Attribute</TableCell>
            <TableCell width={176}>Units</TableCell>
            <TableCell width={56} />
          </TableRow>
        </TableHead>

        {renderBody()}
      </Table>
      {error && (
        <Typography color='error'>
          You have to pick at least one sensor group&apos;s sensor
        </Typography>
      )}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={deleteDevice}>Delete</MenuItem>
      </Menu>
    </TableContainer>
  );
};

DeviceTable.defaultProps = {
  error: false,
} as Props;

export default DeviceTable;
