import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  userCard: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
  },
  userName: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 500,
    margin: '16px 0',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiButton-root:last-child': {
      marginLeft: '10px',
    },
  },
}));
