import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { UserCard, NoUsersOnline } from './components';
import MainLayout from '../MainLayout';
import useClasses from './styles';
import { fetchUsers } from './actions';
import { selectUsers } from './selectors';
import defaultAvatar from './assets/default-avatar.png';

const PeoplePage = (): JSX.Element => {
  const classes = useClasses();
  const dispatch = useDispatch();
  const users: User[] = useSelector(selectUsers);

  useEffect((): void => {
    dispatch(fetchUsers());
  }, []);

  return (
    <MainLayout>
      <Box>
        <Typography className={classes.title}>People</Typography>
        <Box className={classes.peoplePage}>
          {isEmpty(users) ? (
            <NoUsersOnline />
          ) : (
            users
              // TODO: uncomment when WebRTC in XR will be implemented
              // .filter(
              //   ({ platforms, email }) =>
              //     email === 'test@test.me' || platforms.includes('XR')
              // )
              .map((user: User) => (
                <UserCard
                  {...user}
                  key={user.uid}
                  photoUrl={
                    isEmpty(user.photoUrl) ? defaultAvatar : user.photoUrl
                  }
                />
              ))
          )}
        </Box>
      </Box>
    </MainLayout>
  );
};

export default PeoplePage;
