import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      zIndex: 2000,
      background: '#FFF',
      position: 'absolute',
    },
  })
);

export default useStyles;
