import { createSelector } from '@reduxjs/toolkit';

export const selectWizardState = (state: RootState): EquipmentState.Wizard =>
  state.equipment.wizard;

export const selectIsSerialNumberUnique = createSelector(
  selectWizardState,
  (state) => state.isSerialNumberUnique
);

export const selectRequestStatus = createSelector(
  selectWizardState,
  (state) => state.requestStatus
);

export const selectEquipment = createSelector(
  selectWizardState,
  (state) => state.equipment
);

export const selectEquipmentDevices = createSelector(
  selectEquipment,
  (equipment): Device[] => equipment?.iot_device || []
);

export const selectAvailableDevices = createSelector(
  selectWizardState,
  (state): Device[] => state.devices.groups
);
