import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqueId } from 'lodash';

import { Box, Button, Typography } from '@material-ui/core';

import clsx from 'clsx';
import { useFormik } from 'formik';

import PageLeaveWarning, { useHandlePageLeave } from '../../PageLeaveWarning';

import GeneralInformation from './GeneralInformation';
import StepFields from './StepFields';
import {
  createChecklist,
  updateChecklist,
  deleteChecklistFile,
  resetChecklistFormState,
  deleteStepFilesData,
} from '../actions';
import { MAP_FILE_FORMAT_BY_CATEGORY } from '../constants';
import { useInitializeFormikForEditMode } from '../hooks';
import {
  generateInitialValues,
  transformFormikValuesToPayload,
} from '../utils';
import { selectRequestStatus, selectFilesByStepIndex } from '../selectors';
import {
  ServerFileNamesToDelete,
  FileCategory,
  FormikValues,
  StepWithId,
} from '../types';
import validationSchema from '../validation';

import useStyles from './styles';
import { KeyPageElements } from '../../../constants/global';

interface Props {
  editChecklistId: string;
  closeForm(): void;
  openSnackbar(isEditMode: boolean): void;
}

const ChecklistForm = ({
  editChecklistId,
  closeForm,
  openSnackbar,
}: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialStepId = uniqueId();
  const [stepIds, setStepIds] = useState<string[]>([initialStepId]);

  const filesByStepIndex = useSelector(selectFilesByStepIndex);

  const [
    shouldDisplayWarning,
    setHasUnsavedChanges,
    handleWarningSubmit,
    handleWarningClose,
  ] = useHandlePageLeave({
    excludedPageLeaveElements: [KeyPageElements.Checklists],
  });

  const handleSubmit = (values: FormikValues): void => {
    if (!editChecklistId) {
      const payload = transformFormikValuesToPayload(values, filesByStepIndex);

      dispatch(createChecklist(payload));
    } else {
      const payload = {
        ...transformFormikValuesToPayload(values, filesByStepIndex),
        checklistId: editChecklistId,
      };

      dispatch(updateChecklist(payload));
    }
  };

  const initialValues = generateInitialValues(stepIds);

  const formik = useFormik<FormikValues>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  const {
    values,
    values: { steps, name },
    dirty,
  } = formik;

  useEffect(() => {
    if (dirty) {
      setHasUnsavedChanges(true);
    }
  }, [values, dirty]);

  const addStepId = (): string => {
    const stepId = uniqueId();

    setStepIds((ids) => [...ids, stepId]);

    return stepId;
  };

  const addStep = (): void => {
    const id = addStepId();

    const nextSteps: StepWithId[] = [
      ...steps,
      { id, title: '', description: '' },
    ];

    formik.setFieldValue('steps', nextSteps);
  };

  const removeStep = (stepId: string): void => {
    setStepIds((ids) => ids.filter((id) => id !== stepId));

    const nextSteps = steps.filter((step) => step.id !== stepId);

    formik.setFieldValue('steps', nextSteps);
  };

  const deleteStepFiles = (stepIndex: number): void => {
    const serverFileNamesToDelete: ServerFileNamesToDelete = Object.entries(
      filesByStepIndex?.[stepIndex]
    )
      .filter(([key, value]) => key in MAP_FILE_FORMAT_BY_CATEGORY && value)
      .map(([key, value]) => ({
        category: key as FileCategory,
        serverFileName: value,
      }));

    serverFileNamesToDelete.forEach(({ category, serverFileName }) => {
      dispatch(deleteChecklistFile({ stepIndex, category, serverFileName }));
    });

    dispatch(deleteStepFilesData(stepIndex));
  };

  const renderStepFields = (): JSX.Element[] =>
    stepIds.map((id, index) => (
      <Box key={id}>
        <Box className={classes.divider} />
        <StepFields
          index={index}
          isRemovable={stepIds.length !== 1}
          formik={formik}
          removeStep={(): void => {
            removeStep(id);
            deleteStepFiles(index);
          }}
        />
      </Box>
    ));

  const hasStepWithEmptyName = steps.some((step) => !step.title);

  const requestStatus = useSelector(selectRequestStatus);

  const isSaveButtonDisabled =
    !name || hasStepWithEmptyName || requestStatus === 'PENDING';

  useEffect(() => {
    if (requestStatus === 'DONE') {
      openSnackbar(Boolean(editChecklistId));

      setHasUnsavedChanges(false);
      closeForm();
    }
  }, [requestStatus]);

  useInitializeFormikForEditMode({
    editChecklistId,
    stepIds,
    addStepId,
    formik,
  });

  useEffect(
    () => (): void => {
      dispatch(resetChecklistFormState());
    },
    []
  );

  return (
    <>
      <GeneralInformation
        isEditMode={Boolean(editChecklistId)}
        formik={formik}
      />
      {renderStepFields()}
      <Box pt={2} pb={8} px={9}>
        <Button
          variant='outlined'
          onClick={addStep}
          className={clsx(classes.button, 'addStep')}
        >
          Add step
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={isSaveButtonDisabled}
          onClick={formik.submitForm}
          className={clsx(classes.button, 'saveChecklist')}
        >
          <Typography component='span' variant='h5'>
            Save checklist
          </Typography>
        </Button>
      </Box>
      <PageLeaveWarning
        open={shouldDisplayWarning}
        onSubmit={handleWarningSubmit}
        onClose={handleWarningClose}
      />
    </>
  );
};

export default ChecklistForm;
