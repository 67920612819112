import { useHistory } from 'react-router';

import MuiMenuItem from '@material-ui/core/MenuItem';

import { Path } from '../../constants/router';

import useStyles from './styles';

export interface Props {
  path: Path;
  icon: string;
  title: string;
}

const MenuItem = ({ path, icon, title }: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const isMenuItemSelected: boolean = history.location.pathname.includes(path);

  const handleClick = (): void => {
    history.push(path);
  };

  return (
    <MuiMenuItem
      selected={isMenuItemSelected}
      onClick={handleClick}
      className={classes.menuItem}
    >
      <img src={icon} alt={`${title}-icon`} className={classes.icon} />
      {title}
    </MuiMenuItem>
  );
};

export default MenuItem;
