import { createAction } from '@reduxjs/toolkit';

export const SET_REQUEST_STATUS = 'SET_REQUEST_STATUS';
export const setRequestStatus = createAction<RequestStatus>(SET_REQUEST_STATUS);

export const FETCH_EQUIPMENT = 'FETCH_EQUIPMENT';
export const FETCH_EQUIPMENT_SUCCESS = 'FETCH_EQUIPMENT_SUCCESS';
export const fetchEquipment = createAction<string>(FETCH_EQUIPMENT);
export const fetchEquipmentSuccess = createAction<EquipmentList.EquipmentItem>(
  FETCH_EQUIPMENT_SUCCESS
);

export const CREATE_EQUIPMENT = 'CREATE_EQUIPMENT';
export const CREATE_EQUIPMENT_SUCCESS = 'CREATE_EQUIPMENT_SUCCESS';

export const createEquipment =
  createAction<EquipmentGeneralInformation>(CREATE_EQUIPMENT);
export const createEquipmentSuccess = createAction<
  Partial<EquipmentList.EquipmentItem>
>(CREATE_EQUIPMENT_SUCCESS);

export const SET_IS_SERIAL_NUMBER_UNIQUE = 'SET_IS_SERIAL_NUMBER_UNIQUE';
export const setIsSerialNumberUnique = createAction<boolean>(
  SET_IS_SERIAL_NUMBER_UNIQUE
);

export const FETCH_DEVICES = 'FETCH_DEVICES';
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';

export const fetchDevices = createAction(FETCH_DEVICES);
export const fetchDevicesSuccess = createAction<DevicesResponse>(
  FETCH_DEVICES_SUCCESS
);

export const ADD_EQUIPMENT_DEVICES = 'ADD_EQUIPMENT_DEVICES';
export const addEquipmentDevices = createAction<AddDevicePayload>(
  ADD_EQUIPMENT_DEVICES
);

export const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT';
export const updateEquipment =
  createAction<EquipmentList.EquipmentItem>(UPDATE_EQUIPMENT);
