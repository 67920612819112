import { Box } from '@material-ui/core';

import clsx from 'clsx';

import { StepType, StepIndex } from '../../constants';
import useStyles from './styles';

interface Props {
  type: StepType;
  isEditMode: boolean;
  title: string;
  index: number;
  setStepIndex(index: StepIndex): void;
}

const HeaderStep = ({
  type,
  isEditMode,
  title,
  index,
  setStepIndex,
}: Props): JSX.Element => {
  const classes = useStyles();
  const chevronIconCn = clsx(classes.chevronIcon, type);
  const headerStepTitleCn = clsx(classes.headerStepTitle, type);
  const headerStepCn = clsx(classes.headerStep, { edit: isEditMode });

  const shouldDisplayIcon: boolean = index !== 0 && !isEditMode;

  const fullTitle = isEditMode ? title : `${index + 1}. ${title}`;

  const handleClick = (): void => {
    if (isEditMode) {
      setStepIndex(index);
    }
  };

  return (
    <Box className={headerStepCn} onClick={handleClick}>
      {shouldDisplayIcon && <div className={chevronIconCn} />}
      <Box className={headerStepTitleCn}>{fullTitle}</Box>
    </Box>
  );
};

export default HeaderStep;
