import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { getIdToken } from '../components/Auth/auth';

class HTTP {
  readonly baseUrl: string;

  readonly axios: AxiosInstance;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.axios = axios.create();

    this.axios.interceptors.request.use(async (config) => {
      const accessToken = await getIdToken();

      // eslint-disable-next-line no-param-reassign
      config.headers!.Authorization = `Bearer ${accessToken}`;

      return config;
    });
  }

  static onSuccess(response: any): any {
    return response;
  }

  static throwError(error: any): void {
    throw error;
  }

  get = async (path: string, config?: AxiosRequestConfig): Promise<object> => {
    const url = `${this.baseUrl}${path}/`;
    const accessToken = await getIdToken();
    return this.axios
      .get(
        `https://users.dev.mlinvest.org/api/users/verify-token?token=${accessToken}`
      )
      .then(
        ({ data: response }: any): any => {
          if (typeof response === 'string' && response.length > 0) {
            return this.axios.get(url, config);
          }
          return HTTP.throwError;
        },
        () => {
          window.location.href = '/login';
        }
      )
      .then(HTTP.onSuccess)
      .catch(HTTP.throwError);
  };

  post = async (
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<object> => {
    const url = this.baseUrl + path;
    const accessToken = await getIdToken();

    return this.axios
      .get(
        `https://users.dev.mlinvest.org/api/users/verify-token?token=${accessToken}`
      )
      .then(
        ({ data: response }: any): any => {
          if (typeof response === 'string' && response.length > 0) {
            return this.axios.post(url, data, config);
          }
          return HTTP.throwError;
        },
        () => {
          window.location.href = '/login';
        }
      )
      .then(HTTP.onSuccess)
      .catch(HTTP.throwError);
  };

  put = async (
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<object> => {
    const url = this.baseUrl + path;
    const accessToken = await getIdToken();

    return this.axios
      .get(
        `https://users.dev.mlinvest.org/api/users/verify-token?token=${accessToken}`
      )
      .then(
        ({ data: response }: any): any => {
          if (typeof response === 'string' && response.length > 0) {
            return this.axios.put(url, data, config);
          }
          return HTTP.throwError;
        },
        () => {
          window.location.href = '/login';
        }
      )
      .then(HTTP.onSuccess)
      .then(HTTP.onSuccess)
      .catch(HTTP.throwError);
  };

  delete = async (
    path: string,
    config?: AxiosRequestConfig
  ): Promise<object> => {
    const url = this.baseUrl + path;
    const accessToken = await getIdToken();

    return this.axios
      .get(
        `https://users.dev.mlinvest.org/api/users/verify-token?token=${accessToken}`
      )
      .then(
        ({ data: response }: any): any => {
          if (typeof response === 'string' && response.length > 0) {
            return this.axios.delete(url, config);
          }
          return HTTP.throwError;
        },
        () => {
          window.location.href = '/login';
        }
      )
      .then(HTTP.onSuccess)
      .then(HTTP.onSuccess)
      .catch(HTTP.throwError);
  };
}

export default HTTP;
