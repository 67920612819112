import { createAction } from '@reduxjs/toolkit';

export const FETCH_CHECKLISTS = 'FETCH_CHECKLISTS';
export const FETCH_CHECKLISTS_SUCCESS = 'FETCH_CHECKLISTS_SUCCESS';
export const DELETE_CHECKLIST = 'DELETE_CHECKLIST';

export const fetchChecklists = createAction(FETCH_CHECKLISTS);
export const fetchChecklistsSuccess = createAction<GetChecklistsResponse>(
  FETCH_CHECKLISTS_SUCCESS
);
export const deleteChecklist = createAction<string>(DELETE_CHECKLIST);
