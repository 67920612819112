import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { Path } from '../../../constants/router';

import gcpApi from '../../../services/gcpApi';
import { showNotification } from '../../NotificationsManager/actions';

import {
  FETCH_EQUIPMENT,
  fetchEquipmentSuccess,
  CREATE_EQUIPMENT,
  FETCH_DEVICES,
  UPDATE_EQUIPMENT,
  ADD_EQUIPMENT_DEVICES,
  setRequestStatus,
  createEquipmentSuccess,
  setIsSerialNumberUnique,
  fetchDevicesSuccess,
  updateEquipment as updateEquipmentAction,
} from './actions';
import { selectEquipment } from './selectors';
import { getCreateEquipmentPayload } from './utils';

export function* fetchEquipment({ payload: id }: Action<string>): SagaIterator {
  try {
    const { data: equipment }: Response<EquipmentList.EquipmentItem> =
      yield call(gcpApi.get, `/equipments/${id}`);

    yield put(fetchEquipmentSuccess(equipment));
  } catch (error) {
    console.error(error);
  }
}

export function* createEquipment({
  payload,
}: Action<EquipmentGeneralInformation>): SagaIterator {
  try {
    yield put(setRequestStatus('PENDING'));

    const apiPayload: CreateEquipmentPayload =
      getCreateEquipmentPayload(payload);

    const response = yield call(gcpApi.post, '/equipments', apiPayload);
    const id = response.data;

    const equipment: EquipmentList.EquipmentItem = {
      ...apiPayload,
      id,
    };

    yield put(createEquipmentSuccess(equipment));
    yield put(
      showNotification({ message: 'Equipment is added', type: 'success' })
    );
    yield put(setRequestStatus('DONE'));
  } catch (error: any) {
    if (error?.response?.status === 422) {
      yield put(setIsSerialNumberUnique(false));
    }
  }
}

export function* fetchDevices(): SagaIterator {
  try {
    yield delay(100);

    const { data } = yield call(gcpApi.get, '/devices');

    yield put(fetchDevicesSuccess(data));
  } catch (e) {
    console.error(e);
  }
}

export function* addEquipmentDevices(
  action: Action<AddDevicePayload>
): SagaIterator {
  try {
    const { devices, history } = action.payload;
    const prevEquipment = yield select(selectEquipment);
    const equipment: EquipmentList.EquipmentItem = {
      ...prevEquipment,
      iot_device: devices,
    };

    yield put(updateEquipmentAction(equipment));
    yield delay(500);

    history.push(Path.Root);
  } catch (e) {
    console.error(e);
  }
}

export function* updateEquipment({
  payload,
}: Action<UpdateEquipmentPayload>): SagaIterator {
  try {
    yield put(setRequestStatus('PENDING'));

    const equipmentId = payload.id;

    yield call(gcpApi.put, `/equipments/${equipmentId}`, payload);

    yield put(
      showNotification({ message: 'Equipment is updated', type: 'success' })
    );
    yield put(setRequestStatus('DONE'));
  } catch (e) {
    console.error(e);
  }
}

export default function* wizardSaga(): SagaIterator {
  yield all([
    takeLatest(FETCH_EQUIPMENT, fetchEquipment),
    takeLatest(CREATE_EQUIPMENT, createEquipment),
    takeLatest(FETCH_DEVICES, fetchDevices),
    takeLatest(ADD_EQUIPMENT_DEVICES, addEquipmentDevices),
    takeLatest(UPDATE_EQUIPMENT, updateEquipment),
  ]);
}
