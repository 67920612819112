/* eslint-disable class-methods-use-this */

import Signaling from './socket';
import { defaultMediaConfig, SignalingMessageType } from '../constants';

const CONFIG: RTCConfiguration = {
  iceServers: [
    {
      urls: ['turn:coturn.dev.mlinvest.org:3478'],
      username: 'test',
      credential: 'testpass',
    },
  ],
  iceTransportPolicy: 'relay',
};

class RPCHandler {
  private receiverId: string;

  private callerId: string;

  private peerConnection!: RTCPeerConnection;

  private mediaStream!: MediaStream | null;

  private static instance: RPCHandler;

  private constructor() {
    this.receiverId = '';
    this.callerId = '';
  }

  public static getInstance(): RPCHandler {
    if (!RPCHandler.instance) {
      RPCHandler.instance = new RPCHandler();
    }

    return RPCHandler.instance;
  }

  private onICEConnectionStateChange(stateCallback: any) {
    return (): void => {
      switch (this.peerConnection.iceConnectionState) {
        case 'completed':
          console.log('ice connection finalized'); // we have established connection and all streams should be working now
          break;
        case 'failed':
          // failed ice connection
          console.log('ice connection failed');
          break;
        default:
          console.log(
            `Ice connection state is: ${this.peerConnection.iceConnectionState}`
          );
      }

      if (stateCallback && typeof stateCallback === 'function') {
        stateCallback(this.peerConnection.iceConnectionState);
      }
    };
  }

  private onTrack(mediaCallback: any) {
    return (ev: RTCTrackEvent): void => {
      mediaCallback(ev);
    };
  }

  private createOffer(to: string): void {
    this.prepareMedia();

    this.peerConnection
      .createOffer()
      .then((offer) => {
        this.peerConnection.setLocalDescription(offer);
        Signaling.emit(SignalingMessageType.OFFER_SDP, {
          to,
          sdp: offer,
        });
      })
      .catch((error): void => console.error(error));
  }

  private async createAnswer(
    to: string,
    sdp: string,
    type: RTCSdpType
  ): Promise<any> {
    if (!this.mediaStream) {
      const stream = await navigator.mediaDevices.getUserMedia(
        defaultMediaConfig
      );
      this.mediaStream = stream;
      stream.getTracks().forEach((t: MediaStreamTrack): void => {
        this.peerConnection.addTrack(t, stream);
      });
    }

    this.peerConnection
      .setRemoteDescription(this.buildRTCSessionDescription(sdp, type))
      .then(() => {
        this.peerConnection
          .createAnswer()
          .then((answer) => {
            console.log('this.mediaStream', this.mediaStream);

            this.peerConnection.setLocalDescription(answer);
            Signaling.emit(SignalingMessageType.ANSWER_SDP, {
              to,
              sdp: answer,
            });
          })
          .catch((error): void => console.error(error));
      })
      .catch((error): void => console.error(error));
  }

  private async prepareMedia(): Promise<any> {
    if (!this.mediaStream) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(
          defaultMediaConfig
        );
        this.mediaStream = stream;
        stream.getTracks().forEach((t: MediaStreamTrack): void => {
          this.peerConnection.addTrack(t, stream);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  public toggleAudio(flag: boolean): void {
    const audio = this.mediaStream?.getAudioTracks()[0];
    if (audio) {
      audio.enabled = flag;
    }
  }

  public toggleVideo(flag: boolean): void {
    const video = this.mediaStream?.getVideoTracks()[0];
    if (video) {
      video.enabled = flag;
    }
  }

  private stopMedia(): void {
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track: MediaStreamTrack): void => {
        track.stop();
        this.mediaStream?.removeTrack(track);
      });
      this.mediaStream = null;
    }
  }

  // Subscribe to websocket events
  /*
  User 2
    1. Signaling.on(SignalingMessageType.ANSWER_SDP, {...})
      создает экземпляр RTCPeerConnection и добавляет в него предложение в виде RTCSessionDescription,
      с помощью метода setRemoteDescription
      - const sdp = new RTCSessionDescription(desc)
      - pc.setRemoteDescription(sdp)

    2. при добавлении А треков и потока в экземпляр RTCPeerConnection на стороне Б возникает событие track,
      которое обрабатывается с помощью ontrack
      - pc.ontrack = ({ streams }) => { remoteStream = streams[0] }

    3. захватывает медиапоток с устройств пользователя, добавляет треки и поток в экземпляр RTCPeerConnection,
      генерирует ответ на предложение об установке соединения (answer) с помощью метода createAnswer,
      вызывает setLocalDescription с ответом и передает ответ А:
      - const answer = await pc.createAnswer()
        pc.setLocalDescription(answer)

      // сигнализация
      Signaling.emit(SignalingMessageType.ANSWER_SDP, {
        // идентификатор А
        to: remoteId,
        sdp: answer
      })
    4. А, в свою очередь, также вызывает setRemoteDescription и регистрирует ontrack
    5. в это же время (после вызова setLocalDescription) происходит подбор кандидатов для установки интерактивного соединения (ICE gathering)
      - pc.onicecandidate = ({ candidate }) => {
          // событие содержит `RTCIceCandidateInit`
          // https://w3c.github.io/webrtc-pc/#dom-rtcicecandidateinit
          // передаем "кандидата" другой стороне
          socket.emit('call', {
            to: remoteId,
            candidate
          })
        }
    6. при получении "кандидата" другой стороной, она создает экземпляр RTCIceCandidate
      с помощью одноименного конструктора и вызывает метод addIceCandidate
      - const _candidate = new RTCIceCandidate(candidate)
        // https://w3c.github.io/webrtc-pc/#dom-peerconnection-addicecandidate
        pc.addIceCandidate(_candidate)

    7. после этого стороны могут напрямую обмениваться медиаданными
*/

  public startAnswer(
    receiverId: string,
    callerId: string,
    mediaCallback: any,
    stateCallback?: any
  ): void {
    this.receiverId = receiverId;
    this.callerId = callerId;

    this.peerConnection = new RTCPeerConnection(CONFIG);
    this.peerConnection.ontrack = this.onTrack(mediaCallback);
    this.peerConnection.onicecandidate = ({
      candidate,
    }: RTCPeerConnectionIceEvent): void => {
      if (candidate) {
        Signaling.emit(SignalingMessageType.ICE_CANDIDATE, {
          to: this.callerId,
          ice: candidate,
        });
      }
    };

    Signaling.on(SignalingMessageType.OFFER_SDP, (message: SocketMessage) => {
      const { payload: sdp, senderId } = message;
      // we received offer from remote host
      // receiverId (callee)
      if (this.receiverId !== senderId) {
        this.createAnswer(senderId, sdp, 'offer');
      }
    });

    Signaling.on(
      SignalingMessageType.ICE_CANDIDATE,
      (message: SocketMessage): void => {
        const { payload, senderId } = message;
        if (this.receiverId !== senderId) {
          // Web to Web
          if (payload.candidate) {
            this.peerConnection.addIceCandidate(new RTCIceCandidate(payload));
          } else {
            // ML to Web
            const ice = JSON.parse(payload);
            this.peerConnection.addIceCandidate(
              new RTCIceCandidate({
                candidate: ice.candidate,
                sdpMid: ice.sdpMid,
                sdpMLineIndex: ice.sdpMLineIndex,
                usernameFragment: ice.userNameFragment,
              })
            );
          }
        }
      }
    );

    this.peerConnection.oniceconnectionstatechange =
      this.onICEConnectionStateChange(stateCallback);
  }

  // Function we call when we want to call

  /*
  User 1
    1. захватывает (capture) медиапоток с устройств
      - const localStream = await navigator.mediaDevices.getUserMedia(config)
    2. создает экземпляр RTCPeerConnection
      - const pc = new RTCPeerConnection(config)
    3. добавляет захваченные треки и поток в экземпляр RTCPeerConnection с помощью метода addTrack
      - stream.getTracks().forEach((track) => pc.addTrack(track, stream))
    4. генерирует предложение (offer)
      - const offer = await pc.createOffer()
    5. вызывает метод setLocalDescription, передавая ему предложение
      - pc.setLocalDescription(offer)
    6. предложение передается браузеру пользователя
      - Signaling.emit(
        SignalingMessageType.OFFER_SDP, {
          to: remoteId,
          sdp: offer
        });
*/
  public startRTCCall(
    receiverId: string,
    callerId: string,
    mediaCallback: any,
    stateCallback?: any
  ): void {
    this.receiverId = receiverId;
    this.callerId = callerId;

    this.peerConnection = new RTCPeerConnection(CONFIG);
    this.peerConnection.ontrack = this.onTrack(mediaCallback);
    this.peerConnection.onicecandidate = ({
      candidate,
    }: RTCPeerConnectionIceEvent): void => {
      if (candidate) {
        Signaling.emit(SignalingMessageType.ICE_CANDIDATE, {
          to: this.receiverId,
          ice: candidate,
        });
      }
    };

    this.createOffer(receiverId);

    Signaling.on(
      SignalingMessageType.ANSWER_SDP,
      ({ payload: sdp }: SocketMessage) => {
        this.peerConnection.setRemoteDescription(
          this.buildRTCSessionDescription(sdp, 'answer')
        );
      }
    );

    Signaling.on(
      SignalingMessageType.ICE_CANDIDATE,
      (data: SocketMessage): void => {
        // Call from WEB
        if (data.payload.candidate) {
          this.peerConnection.addIceCandidate(
            new RTCIceCandidate(data.payload)
          );
        } else {
          // Call from ML
          this.peerConnection.addIceCandidate(
            new RTCIceCandidate(JSON.parse(data.payload))
          );
        }
      }
    );

    this.peerConnection.oniceconnectionstatechange =
      this.onICEConnectionStateChange(stateCallback);
    this.peerConnection.onnegotiationneeded = (/* _event: any */): void => {
      this.createOffer(receiverId);
    };
  }

  public endCall(): void {
    this.stopMedia();
    this.peerConnection?.close();
  }

  private buildRTCSessionDescription(
    sdp: string,
    type: RTCSdpType
  ): RTCSessionDescription {
    return new RTCSessionDescription({ sdp, type });
  }
}

export default RPCHandler;
