import { Typography, Box } from '@material-ui/core';

import useStyles from './styles';

export interface HeaderProps {
  serialNumber: string;
  location: string;
  created: string;
  updated: string;
}

const Header = ({
  serialNumber,
  location,
  created,
  updated,
}: HeaderProps): JSX.Element => {
  const classes = useStyles();

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  return (
    <Box className={classes.details}>
      <Box>
        <Typography className={classes.keyDetail}>Serial Number:</Typography>
        <Typography variant='h6' className={classes.valueDetail}>
          {serialNumber}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.keyDetail}>Location:</Typography>
        <Typography variant='h6' className={classes.valueDetail}>
          {location}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.keyDetail}>Created:</Typography>
        <Typography variant='h6' className={classes.valueDetail}>
          {new Date(created).toLocaleString('en-US', dateOptions)}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.keyDetail}>Updated:</Typography>
        <Typography variant='h6' className={classes.valueDetail}>
          {new Date(updated).toLocaleString('en-US', dateOptions)}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
