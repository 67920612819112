import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import useStyles from './styles';

const NOT_AVAILABLE = 'NotAvailable';

const Sensor = ({
  attribute,
  units,
  currentValue: value,
}: EquipmentList.SensorItem): JSX.Element => {
  const classes = useStyles();
  const isNotAvailable = value === NOT_AVAILABLE;

  return (
    <Box
      className={clsx(classes.sensor, {
        notAvailable: isNotAvailable,
      })}
    >
      <Box display='flex' justifyContent='space-between'>
        <Typography className={classes.name}>{attribute}</Typography>
        <Typography className={classes.unit}>{units}</Typography>
      </Box>
      <Typography className={classes.value}>
        {isNotAvailable ? 'N/A' : value}
      </Typography>
    </Box>
  );
};

Sensor.defaultProps = {
  className: '',
};

export default Sensor;
