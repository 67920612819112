import { MouseEvent, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import {
  Popover,
  MenuItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import useStyles from './styles';

export interface Option {
  key: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  onClick?(): void;
}

interface Props {
  options: Option[];
}

const Menu = ({ options }: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const toggleAccountMenu = (e: MouseEvent<HTMLElement>): void => {
    setAnchorEl((prevState) => (prevState ? null : e.currentTarget));
  };

  const menuItemDecorator =
    (clickCallback: any | undefined) =>
    (e: MouseEvent<HTMLElement>): void => {
      if (typeof clickCallback === 'function') {
        clickCallback();
      }
      toggleAccountMenu(e);
    };

  const id = open ? 'item-menu-popover' : undefined;

  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={toggleAccountMenu}
      >
        <MoreHoriz />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={toggleAccountMenu}
        className={classes.menuRoot}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map(
          ({ key, icon: Icon, onClick }): JSX.Element => (
            <MenuItem
              key={key}
              onClick={menuItemDecorator(onClick)}
              className={classes.menuContainer}
            >
              <ListItemIcon>
                <Icon fontSize='small' />
              </ListItemIcon>
              <ListItemText primary={key} />
            </MenuItem>
          )
        )}
      </Popover>
    </div>
  );
};

export default Menu;
