import { SignalingMessageType, SignalingPlatform } from '../constants';

const SERVER_URI =
  process.env.REACT_APP_SOCKET_URL ||
  'wss://ws-signal-server.dev.mlinvest.org/';

const doNothing = (): void => {};

const THIRTY_SECONDS = 30000;

// 'wss://webrtc-ss.dev.mlinvest.org/'
// http://localhost:4000/

class Signaling {
  private static instance: Signaling;

  private url = SERVER_URI; // 'ws://localhost:9001/';

  private socket!: WebSocket;

  private id!: string;

  private token!: string;

  private eventListeners!: Record<string, any>;

  private unicInstanceId!: symbol;

  private constructor() {
    this.unicInstanceId = Symbol(
      Math.floor(Math.random() * 10000000).toString()
    );
  }

  public static getInstance(): Signaling {
    if (!Signaling.instance) {
      Signaling.instance = new Signaling();
    }

    return Signaling.instance;
  }

  public init(id: string, token: string): void {
    this.id = id;
    this.token = token;
    this.verifyInstance();
    this.on(SignalingMessageType.PING_PONG, doNothing);
  }

  public on(eventName: string, callback: any): void {
    this.eventListeners = { ...this.eventListeners, [eventName]: callback };
  }

  public getUnicInstanceId(): symbol {
    return this.unicInstanceId;
  }

  public killAll(): void {
    this.eventListeners = {};
  }

  public emit(eventName: string, data: any): void {
    switch (eventName) {
      case SignalingMessageType.CALL:
        this.sendMessage(data.to, SignalingMessageType.CALL, '');
        break;
      case SignalingMessageType.BUSY:
        this.sendMessage(data.to, SignalingMessageType.BUSY, '');
        break;
      case SignalingMessageType.ACCEPT:
        this.sendMessage(data.to, SignalingMessageType.ACCEPT, '');
        break;
      case SignalingMessageType.END_CALL:
        this.sendMessage(data.to, SignalingMessageType.END_CALL, '');
        break;
      case SignalingMessageType.OFFER_SDP:
        this.sendMessage(data.to, SignalingMessageType.OFFER_SDP, data.sdp.sdp);
        break;
      case SignalingMessageType.ANSWER_SDP:
        this.sendMessage(
          data.to,
          SignalingMessageType.ANSWER_SDP,
          data.sdp.sdp
        );
        break;
      case SignalingMessageType.ICE_CANDIDATE:
        this.sendMessage(data.to, SignalingMessageType.ICE_CANDIDATE, data.ice);
        break;
      default:
        console.error('emited default case');
    }
  }

  private sendMessage(to: string, type: any, data: string): void {
    this.socket.send(
      JSON.stringify({
        senderId: this.id,
        messageType: type,
        platform: SignalingPlatform.WEB,
        payload: data,
        receiverId: to,
      } as SocketMessage)
    );
  }

  private verifyInstance(): void {
    if (this.socket) return;

    this.socket = new WebSocket(
      `${this.url}?id=${this.id}&token=${this.token}`
    );
    this.socket.addEventListener('open', (event) => {
      console.log('websocket opened', event);
    });
    this.socket.addEventListener('message', (event) => {
      const message: SocketMessage = JSON.parse(event.data);

      this.eventListeners[message.messageType](message);
    });

    const intervalId = setInterval((): void => {
      this.sendMessage(this.id, SignalingMessageType.PING_PONG, 'ping-pong');
    }, THIRTY_SECONDS);

    this.socket.onclose = (event): void => {
      console.log('websocket closed', event);
      clearInterval(intervalId);
    };
  }
}

/*
UI
1. .emit('call')  ->  .on('call') .on('busy') or .on('accept')
// 'call'
// 'busy'
// 'accept'

WebRTC
2. emit('offer') -> .on('offer',)
3. on('answer')  <- emit('answer')
4. emit('icecandidate') 
// 'offer'
// 'answer'
// 'icecandidate'

UI
// 'end'


*/
/*
PeerConnection

CreateOffer
        -> signaling server ->
                          SetRemote
                          CreateAnswer
        <- signaling server <-
SetRemote

onIceCandidate
        -> signaling server -> addIceCandidate

                        onIceCandidate
        <- signaling server <-
addIceCandidate

------------------------------
addTrack -> WebRTC -> onTrack
*/

const instance = Signaling.getInstance();

export default instance as Signaling;
