import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import useStyles from './styles';

interface Props {
  open: boolean;
  onSubmit(): void;
  onClose(): void;
}

const PageLeaveWarning = ({ open, onSubmit, onClose }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>You have unsaved changes that will be lost</DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          color='primary'
          className={classes.primaryButton}
          variant='contained'
        >
          OK
        </Button>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PageLeaveWarning;
