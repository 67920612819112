import { createSelector } from '@reduxjs/toolkit';

export const selectVideoCallState = (state: RootState): VideoCallState =>
  state.videoCall;

export const selectUserToCall = createSelector(
  selectVideoCallState,
  (videoCallState) => videoCallState.userToCall
);

export const selectShowVideoModal = createSelector(
  selectVideoCallState,
  (videoCallState) => videoCallState.isVideoModalShown
);

export const selectMediaConfig = createSelector(
  selectVideoCallState,
  (videoCallState) => videoCallState.mediaConfig
);

export const selectСallingMode = createSelector(
  selectVideoCallState,
  (videoCallState) => videoCallState.callingMode
);

export const selectRemoteStreamSrc = createSelector(
  selectVideoCallState,
  (videoCallState) => videoCallState.remoteStreamSrc
);
