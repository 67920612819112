import MuiMenuList from '@material-ui/core/MenuList';

import equipmentIcon from './icons/equipment.svg';
import checklistsIcon from './icons/checklists.svg';
import peopleIcon from './icons/people.svg';

import { Path } from '../../constants/router';

import MenuItem, { Props as MenuItemProps } from './MenuItem';

import useStyles from './styles';
import { KeyPageElements } from '../../constants/global';

const menuItems: MenuItemProps[] = [
  {
    path: Path.Equipment,
    title: KeyPageElements.Equipment,
    icon: equipmentIcon,
  },
  {
    path: Path.Checklists,
    title: KeyPageElements.Checklists,
    icon: checklistsIcon,
  },
  {
    path: Path.People,
    title: KeyPageElements.People,
    icon: peopleIcon,
  },
];

const MenuList = (): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiMenuList className={classes.menu} variant='selectedMenu'>
      {menuItems.map(({ title, path, icon }) => (
        <MenuItem key={path} title={title} path={path} icon={icon} />
      ))}
    </MuiMenuList>
  );
};

export default MenuList;
