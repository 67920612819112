import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Box, Typography } from '@material-ui/core';

import PageLeaveWarning, {
  useHandlePageLeave,
} from '../../../../PageLeaveWarning';

import { addEquipmentDevices } from '../../actions';
import { selectEquipmentDevices } from '../../selectors';

import Footer from '../Footer';

import Autocomplete from './Autocomplete';
import DeviceTable from './DeviceTable';

import useStyles from './styles';
import { KeyPageElements } from '../../../../../constants/global';

const MAX_DEVICES_AMOUNT = 30;

type Errors = { [key: string | 'deviceAmount']: boolean };

interface Props {
  isEditMode: boolean;
}

const Sensors = ({ isEditMode }: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [
    shouldDisplayWarning,
    setHasUnsavedChanges,
    handleWarningSubmit,
    handleWarningClose,
  ] = useHandlePageLeave({
    extraPageLeaveElements: [KeyPageElements.GeneralInformation],
    shouldHandlePageLeave: isEditMode,
  });

  const [isAutocompleteDisplayed, setIsAutocompleteDisplayed] =
    useState<boolean>(true);

  const selectedDevices = useSelector(selectEquipmentDevices);

  const initialSelectedDevices = selectedDevices.reduce(
    (acc: object, device: Device) => ({
      ...acc,
      [device.device_id]: device,
    }),
    {}
  );

  const [devices, setDevices] = useState<{ [key: string]: Device }>(
    initialSelectedDevices
  );

  const deviceEntities = Object.values(devices);
  const totalDevices = deviceEntities.length;

  const [errors, setErrors] = useState<Errors>({});

  const setErrorByKey = (key: string, error: boolean): void => {
    setErrors((prevState) => ({
      ...prevState,
      [key]: error,
    }));
  };

  const deleteDevice = (deviceId: string): void => {
    setHasUnsavedChanges(true);
    setErrorByKey('deviceAmount', false);
    setErrorByKey(deviceId, false);

    setDevices((prevState) => {
      const newState = { ...prevState };
      delete newState[deviceId];

      return newState;
    });
  };

  useEffect(() => {
    if (totalDevices) {
      setHasUnsavedChanges(true);
      setIsAutocompleteDisplayed(false);
    }
  }, [totalDevices]);

  const displayAutocomplete = (): void => {
    setIsAutocompleteDisplayed(true);
  };

  const submitStep = (): void => {
    const unselectedSensorGroups = deviceEntities.filter(
      ({ device_id, sensors }) => {
        const selectedSome = sensors.some(({ enabled }) => enabled);

        if (!selectedSome) setErrorByKey(device_id, true);

        return !selectedSome;
      }
    );

    const hasUnselectedSensorGroups = unselectedSensorGroups.length;

    const isDeviceAmountInvalid = totalDevices > MAX_DEVICES_AMOUNT;

    if (isDeviceAmountInvalid) {
      setErrorByKey('deviceAmount', true);
    }

    if (hasUnselectedSensorGroups || isDeviceAmountInvalid) return;

    setHasUnsavedChanges(false);

    dispatch(addEquipmentDevices({ devices: deviceEntities, history }));
  };

  const handleAutocompleteChange = (e: any, device: Device | null): void => {
    if (device) {
      setDevices((prevDevices) => ({
        ...prevDevices,
        [device.device_id]: {
          ...device,
          sensors: device.sensors.map((sensor) => ({
            ...sensor,
            enabled: true,
          })),
        },
      }));
    }
  };

  const handleSensorSelect =
    (deviceId: string) =>
    (selectedSensorIds: string[]): void => {
      setHasUnsavedChanges(true);
      const newSensors = devices[deviceId].sensors.map((sensor) => ({
        ...sensor,
        enabled: selectedSensorIds.includes(sensor.id),
      }));

      const newState = {
        ...devices,
        [deviceId]: {
          ...devices[deviceId],
          sensors: newSensors,
        },
      };
      setDevices(newState);
    };

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <Typography color='textPrimary' variant='body1' className={classes.text}>
        Click the button below to select sensors so they can be added to the
        equipment. Use checkboxes to indicate sensors which should be displayed
        in XR mode. Please note that the maximum amount of added sensors groups
        is 30.
      </Typography>
      {deviceEntities.map((device) => {
        const { device_id: deviceId } = device;
        const error = errors?.[deviceId];

        return (
          <DeviceTable
            key={deviceId}
            error={error}
            device={device}
            deleteDevice={(): void => deleteDevice(deviceId)}
            onSensorSelect={handleSensorSelect(deviceId)}
          />
        );
      })}
      <Autocomplete
        selectedDevices={devices}
        isDisplayed={isAutocompleteDisplayed}
        onChange={handleAutocompleteChange}
        onButtonClick={displayAutocomplete}
      />
      {!totalDevices ? (
        <Box mt={2}>
          <Typography color='textSecondary'>
            There are no sensors added to this equipment
          </Typography>
        </Box>
      ) : null}
      {errors.deviceAmount && (
        <Typography color='error'>
          You can assign a maximum of 30 sensor groups for the equipment
        </Typography>
      )}
      <Box mt='auto'>
        <Footer isNextButtonDisabled={false} onSaveButtonClick={submitStep} />
      </Box>
      <PageLeaveWarning
        open={shouldDisplayWarning}
        onSubmit={handleWarningSubmit}
        onClose={handleWarningClose}
      />
    </Box>
  );
};

export default Sensors;
